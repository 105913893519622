import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';


function Footer() {
    const [show, setShow] = useState(true);

    function checkPage(){
        if(window.location.pathname == "/payment"){
            setShow(false)
        }
    }
    
    useEffect(() => {
        checkPage();
      }, [])

    return <div className="container-fluid footer" id={show?"ShowBlock":"HideBlock"}>
        <div className="container">
            <div className="row align-items-top p-md-5">

                <div className="col-xl-3 col-lg-3">
                    <img src="https://spixnet.com/images/logo2.webp" alt="spixnet" />
                    <p>spixnet gmbh<br/>Am Anger 1<br/>7210 Mattersburg<br/>Austria</p>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <h2>Helpdesk</h2>
                    <p>Free in AT,DE,CH:<br/>0800 40 444 50</p>
                    <p>International:<br/>+43 720 40 444 50</p>
                    <p>E-Mail:<br/>office@spixnet.com</p>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <h2>Sitemap</h2>
                    <p>Free in AT,DE,CH:<br/>0800 40 444 50</p>
                    <p>International:<br/>+43 720 40 444 50</p>
                    <p>E-Mail:<br/>office@spixnet.com</p>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <h2>Social Media</h2>
                    <ul className="social">
                        <li><a target='_blank' href="https://ne-np.facebook.com/people/Spixnet/100088498921697/"><i className="fa-brands fa-facebook"></i></a></li>
                        <li><a target='_blank' href="https://twitter.com/SpixnetG?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><i className="fa-brands fa-twitter"></i></a></li>
                        <li><a target='_blank' href="https://www.youtube.com/channel/UCHLL1FkpjBSdQN_TtVacsTw?app=desktop&cbrd=1"><i className="fa-brands fa-youtube"></i></a></li>
                        <li><a target='_blank' href="https://www.linkedin.com/company/spixnet-gmbh?original_referer=https%3A%2F%2Fwww.google.com%2F"><i className="fa-brands fa-linkedin"></i></a></li>
                    </ul>
                    <h2>Legal</h2>
                    <ul>
                        <li>
                            <Link to="/impressum" onClick= {() => window.scrollTo(0, 0)}>Imprint</Link>
                        </li>
                        <li>
                            <Link to="/agb" onClick= {() => window.scrollTo(0, 0)}>Terms</Link>
                        </li>
                        <li>
                            <Link to="/datenschutz" onClick= {() => window.scrollTo(0, 0)}>Data Protection</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
}

export default Footer;