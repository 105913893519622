import { useEffect } from 'react';
function Agb(props){
    useEffect(() =>{
        const interval = setInterval(() => {
            //props.func('Agb');
        }, 2000);
        return () => clearInterval(interval);
    },[]);
    return <div className="customPage">
            <div className="container-fluid bggray">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
                            <p>Allgemeine Geschäftsbedingungen (AGB) der spixnet gmbh | Stand 03.10.2022 Informations- und Offenlegungspflicht gemäß § 24 MedienG und § 5 ECG</p>
                            <p>spixnet gmbh<br/>
                            Am Anger 1<br/>
                            7210 Mattersburg, Burgenland<br/>
                            Österreich<br/>
                            AT: 0800 40 444 50<br/>
                            DE: 0800 40 444 50<br/>
                            CH: 0800 40 4445<br/>
                            INT: +43 720 40 444 50<br/>
                            office@spixnet.com<br/>
                            www.spixnet.com</p>
                            <p>Geschäftsführung<br/>
                            Manuel Löw-Beer, MBA MPA<br/>
                            Firmenbuchnummer: FN 579120 y<br/>
                            Firmenbuchgericht: Landesgericht Eisenstadt</p>

                            <ul>
                                <li>Geltungsbereich
                                    <ul>
                                        <li>Diese Allgemeinen Geschäftsbedingungen ("AGB") gelten – soweit nicht ausdrücklich Gegenteiliges vereinbart wurde – für Vertragsabschlüsse zwischen der spixnet gmbh (der "Verkäufer" oder "wir") und dem Kunden. Dies unabhängig davon, ob ein solcher Vertragsabschluss online über unsere Website oder über unsere Hotline geschlossen wird. Im Folgenden sind die Bezeichnungen "Verbraucher" und "Unternehmer" im Sinne des § 1 Konsumentenschutzgesetzes (KSchG) auszulegen. Für Rechtsgeschäfte mit Verbrauchern kommen die unter Punkt 7 geregelten Rücktrittsrechte und Rücktrittsfolgen zur Anwendung, während für Unternehmerzusätzlich die Bestimmungen im Abschnitt II gelten.</li>
                                        <li>Mit der Abgabe seiner Vertragserklärung (siehe Punkt 1.4) akzeptiert der Kunde diese AGB. Vertragsabschlüsse sind nur zu diesen Bedingungen möglich. Abweichenden, entgegenstehenden, einschränkenden oder ergänzenden Geschäftsbedingungen und Regelungen des Kunden müssen wir ausdrücklich zustimmen, damit diese im Einzelfall Vertragsbestandteil werden. Insbesondere gelten Vertragserfüllungshandlungen unsererseits nicht als Zustimmung zu etwaigen von unseren AGB abweichenden Bedingungen.</li>
                                        <li>Bei mehreren, zeitlich auseinander liegenden Vertragsabschlüssen wird jeweils die zum Zeitpunkt der Abgabe der Vertragserklärung (siehe Punkt 1.4) durch den Kunden geltende und durch uns veröffentlichte bzw. zur Verfügung gestellte Fassung der AGB Vertragsbestandteil. Mehrere Vertragsabschlüsse mit einem bestimmten Kunden begründen ohne ausdrückliche schriftliche Vereinbarung eines Rahmenvertrages weder ein Dauerschuldverhältnis noch einen sonstigen Anspruch auf erneuten Abschluss eines Kaufvertrags.</li>
                                        <li>Vertragsschluss
                                            <ul>
                                                <li>Die Beschreibung unserer Produkte auf unserer Website ist kein Angebot im rechtlichen Sinn, sondern eine Aufforderung zur Angebotsstellung. Das Angebot erfolgt jeweils ausschließlich durch den Kunden wie in Punkt 1.4.2 dargestellt.</li>
                                                <li>Auf unserer Website erfolgt das Angebot des Kunden durch die Bestellung des Artikels. Durch Anklicken der Schaltfläche "Jetzt Kaufen", wird der Kunde zum Bestellformular für das jeweils ausgewählte Leistungspaket weitergeleitet und hat darin seinen Daten sowie seine Wunschdomain anzugeben. Für den Fall, dass diese Domain bereits vergeben ist, muss der Kunde durch Anklicken der Schaltfläche "Transfer, ja ich bin Eigentümer" bestätigen, dass er über diese Domain verfügen kann. Für die Übertragung muss der Kunde Details zur Authentifizierung bekanntgeben. Aus Sicherheitsgründen werden vorab keine Details zu Umfang und Inhalt der Anfrage bekanntgegeben.</li>
                                                <li>Das Angebot des Kunden wird durch Klick auf den Button „Zahlungspflichtig bestellen“ für ihn verbindlich. Bitte beachten Sie, dass die Annahme gesondert per E-Mail oder durch faktische Lieferung der bestellten Ware(n) binnen angemessener Frist erfolgt. Unter angemessener Frist ist ein Zeitraum von längstens 5 Werktage zu verstehen. Wir sind berechtigt, Bestellungen auch nur zum Teil anzunehmen oder ohne Angabe von Gründen abzulehnen. Mit Annahme durch den Verkäufer entsteht ein Vertragsverhältnis zwischen dem Kunden und dem Verkäufer. Sollten sie trotz gültig eingegebener E-Mail-Adresse keine Nachricht erhalten, wenden sie sich bitte an uns unter office@spixnet.com.</li>
                                                <li>Der Kunde muss mindestens 18 Jahre und voll geschäftsfähig sein.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Änderung der AGB
                                    <ul>
                                        <li>Wesentliche Änderungen der AGB, einschließlich dieses Punktes, bedürfen der ausdrücklichen oder konkludenten Vereinbarung mit dem Kunden.</li>
                                        <li>Wesentliche Änderungen sind alle Änderungen oder Ergänzungen, die (i) die Hauptleistungspflicht betreffen oder (ii) das Vertragsverhältnis auf eine Weise wesentlich verändern, die dem Abschluss eines neuen Vertrages gleichkommen.</li>
                                        <li>Sonstige Änderungen können jederzeit vorgenommen werden und treten in Kraft
                                            <ul>
                                                <li>sofern kein bestimmtes Datum des Inkrafttretens angegeben wird, zum früheren der beiden folgenden Zeitpunkte: (i) ausdrückliche Annahme der geänderten AGB durch den Kunden oder (ii) Ablauf von zwei Monaten nach Veröffentlichung solcher Änderungen oder Ergänzungen (z.B. durch Zusendung einer Kopie an den Kunden), sofern der Kunde nicht gemäß Punkt 2.4 widersprochen hat; bzw.</li>
                                                <li>sofern ein bestimmtes (nach dem Veröffentlichungsdatum befindliches) Datum des Inkrafttretens angegeben wird, zum späteren der beiden folgenden Zeitpunkte: (i) dem angegebenen Datum des Inkrafttretens und (ii) dem früheren der beiden folgenden Zeitpunkte: (a) ausdrückliche Annahme der geänderten AGB durch den Kunden oder (b) Ablauf von zwei Monaten nach Veröffentlichung solcher Änderungen oder Ergänzungen (z.B. durch Zusendung einer Kopie an den Kunden), sofern der Kunde nicht gemäß Punkt 2.4 widersprochen hat.</li>
                                            </ul>
                                        </li>  
                                        <li>Die Zustimmung des Kunden gilt als erteilt, wenn der Kunde nicht schriftlich oder elektronisch (z.B. per E-Mail) widerspricht. Der Kunde wird in dem Änderungsangebot darauf hingewiesen, dass (i) sein Schweigen durch Unterlassen eines schriftlichen oder elektronischen Widerspruchs (z.B. per E-Mail oder über das Kundenkonto) innerhalb der in den Punkten 2.3.1 und 2.3.2 genannten Frist als Zustimmung zu den Änderungen gilt und dass (ii) der Verbraucherkunde das Recht hat, seinen Vertrag und die vertraglich vereinbarten Leistungen bis zum Inkrafttreten der Änderungen fristlos zu kündigen.</li>
                                        <li>Sonstige Änderungen dürfen ohne Zustimmung des Kunden nur aus wichtigem Grund vorgenommen werden, das heißt
                                            <ul>
                                                <li>zur Klarstellung undeutlicher Bestimmungen;</li>
                                                <li>aufgrund des Wechsels von Dienstleistern oder Subunternehmern;</li>
                                                <li>aufgrund des Angebots neuer Dienstleistungen, die entweder kostenlos sind oder vom Kunden nicht oder nur optional genutzt werden können;</li>
                                                <li>zur Klarstellung aufgrund von Änderungen von Formaten oder Schriftarten;</li>
                                                <li>aufgrund aufsichtsrechtlicher oder steuerrechtlicher Vorgaben;</li>
                                                <li>zur Änderung oder Umsetzung von Nebenpflichten, sofern eine Risikoabwägung unter angemessener Berücksichtigung der Interessen von bzw. der Gesamtheit der Kunden gegenüber den Interessen des einzelnen Kunden erfolgt ist;</li>
                                                <li>sofern keine Nachteile für den Kunden entstehen; oder</li>
                                                <li>ein den angeführten Gründen vergleichbarer Grund vorliegt.</li>
                                            </ul>
                                        </li>
                                        <li>Bei Unternehmern können wesentliche Änderung gemäß Punkt 2.3 und 2.4 durchgeführt werden.</li>                    
                                    </ul>
                                </li>
                                <li>Leistungen des Verkäufers
                                    <ul>
                                        <li>Die Leistungen des Verkäufers umfassen unter anderem:
                                            <ul>
                                                <li>die Einrichtung eines E-Mail Services mit voller Transport- und Inhaltsverschlüsselung inklusive einer digitalen Signatur (sogenanntes sMIME Zertifikat);</li>
                                                <li>die Zurverfügungstellung einer Anti-Spam, Anti-Virus- und Anti-Ransomware; sowie</li>
                                                <li>die Registrierung und Nutzungsüberlassung einer vom Kunden gewählten Domain.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <p>Der Kunde wählt im Verkaufsprozess die konkreten Leistungen des Verkäufers selbst aus. </p>

                            <ul>
                                <li>Nach Vertragsabschluss gemäß Punkt 1.4 wird der Verkäufer ehestmöglich, jedoch längstens innerhalb von fünf Tagen, die vom Kunden gewählte Domain registrieren und bei einer akkreditierten Stelle für den Kunden eine digitale Signatur beantragen, welche für die Inhaltsverschlüsselung der E-Mails genutzt wird. Ferner legt der Verkäufer eine E-Mail-Adresse mit dem Namen des Kunden und der von ihm gewählten Domain an und aktiviert alle spixnet Dienste (insbesondere Verschlüsselung, Anti-Spam, Anti-Virus, Anti-Ramsonware). Anschließend erhält der Kunde die Zugangsdaten (neu angelegte E-Mail-Adresse und Initialpasswort) zu seinem Posteingang. Der Kunde kann durch Mitteilung an support@spixnet.com die E-Mail-Adresse ändern oder weitere Adressen hinzufügen.</li>
                                <li>Sofern Sie Domains von uns beziehen, übertragen wir Ihnen diesbezüglich kein Eigentum, sondern ein nicht- exklusives und nicht-übertragbares Nutzungsrecht. Dieses Nutzungsrecht berechtigt Sie als Kunden lediglich, die Domain für eine bestimmte Zeit zu nutzen. </li>
                                <li>Sollten Sie ihre eigene Domain verwenden, müssen Sie diese an den Verkäufer mit allen Rechten und Pflichten übertragen. Die Zustimmung zur Übertragung der Domain erteilt der Kunde dem Verkäufer im Bestellprozess gesondert. [Wir weisen darauf hin, dass nach Beendigung der Geschäftsbeziehung keine Rückübertragung der Domain erfolgt.]</li>
                            </ul>


                            <ul>
                                <li>Risiken
                                    <p>Mit der Nutzung der Leistungen des Verkäufers gehen insbesondere die folgenden Risiken einher:</p>
                                </li>
                            </ul>


                            <ul>
                                <li><b>Risiko im Zusammenhang mit der Datenmigration.</b> Im Rahmen einer Datenmigration kann es trotz Einhaltung aller gebotenen Sorgfaltsmaßnahmen durch den Verkäufer zu Datenverlusten oder der Beeinträchtigung der Leserlichkeit oder Indexierung von Daten kommen. Dieses Risiko besteht insbesondere, wenn die Migration von alten, nicht gewarteten E-Mail-Systemen erfolgt, der Kunde veraltete Software oder Softwaretechnologien verwendet, die ihr End-of-Life (EOL) bereits erreicht haben, im Fall außergewöhnlich großer Datenmengen oder wenn PST-Dateien migriert werden, ohne dass eine IMAP oder Exchange Datenspiegelung erfolgt. Sofern der Kunde die Daten nicht gesichert hat, besteht daher das Risiko, dass sich Daten nicht wiederherstellen lassen und unwiederbringlich verloren bzw. unleserlich oder nicht indexiert sind.</li>
                                <li><b>Risiko fehlerhafter E-Mail-Zustellungen unmittelbar nach der Datenmigration.</b> Nach der Datenmigration kann es bis zu 72 Stunden dauern, dass alle DNS Server weltweit die neuen Routingeinträge übernehmen. In diesem Zeitraum kann es daher zu fehlerhaften E-Mail-Zustellungen kommen. Der Verkäufer wird sich bemühen, denn Kunden über die Unzustellbarkeit von E-Mails ehestmöglich zu informieren.</li>
                                <li><b>Risiko von Blacklisting.</b> Sofern der Kunde in eine Spam Blacklist eingetragen wurde, werden vom Kunden versandte E-Mails unter Umständen als Spam gewertet und daher automatisiert dem Spam Ordner des jeweiligen Empfängers zugewiesen. Der Verkäufer überprüft regelmäßig, ob seine Kunden auf einer Spam Blacklist aufscheinen, und informiert sie gegebenenfalls über einen solchen Eintrag. Gründe für die Eintragung in einer Spam Blacklist sind beispielsweise der Versand von Massenemails oder ein Antrag auf Eintragung durch einen Dritten. Der Verkäufer hat keinen Einfluss darauf, dass seine Kunden in einer Spam Blacklist aufscheinen</li>
                                <li><b>Risiko von externen DNS Servern.</b> Die vom Verkäufer verwendeten DNS Server sind multiredundant, sodass der Ausfall einzelner Server nicht zu einem Ausfall des IT-Systems des Verkäufers führt. Nur in außergewöhnlichen Fällen wie dem gleichzeitigen Ausfall einer Vielzahl von Server kann es zu einem Ausfall des kompletten IT-Systems des Verkäufers kommen. Sofern ein Kunde die Einbindung eines externen DNS Servers für seine Domain verlangt, wird das Ausfallrisiko der Domain wesentlich erhöht. Daher kann es auch bei Einhaltung aller gebotenen Sorgfaltsmaßnahmen durch den Verkäufer zu einer reduzierten Verfügbarkeit kommen.</li>
                                <li><b>Risiko von Angriffen ohne Verwendung von E-Mails.</b> Die vom Verkäufer angebotene Dienste (Anti-Virus und Anti-Ransomware) interagieren nur mit dem im IT-System des Verkäufers eingebundenen E-Mail-Service. Andere Systeme, wie zB das Computer-Betriebssystem, Netzwerkfestplatten, USB-Sticks, sind nicht geschützt und bedürfen eigener Schutzmaßnahmen (Anti-Virensoftware, Passwordmanager, Festplattenverschlüsselung, Awareness etc.). Sofern der Kunde nicht über (ausreichende) Schutzmaßnahmen verfügt, kann es daher zu Viren- und Ransomware Angriffen ohne Verwendung von E-Mails kommen (zB mittels USB-Stick oder Direktangriff auf die Infrastruktur). </li>
                                <li>Preise
                                    <ul>
                                        <li>Unsere Leistungen sind kostenpflichtig. Die Preise sind unter https://spixnet.at/preise  abrufbar.</li>
                                        <li>Die Preise werden jährlich mit Wirkung zum 01. Februar im Ausmaß der Entwicklung des von der Statistik Austria veröffentlichten nationalen Verbraucherpreisindex 2015 (VPI) oder des an seine Stelle tretenden Index angepasst (Erhöhung oder Senkung), wobei eine kaufmännische Rundung auf den ganzen Cent erfolgt. Die Anpassung entspricht der Veränderung des Durchschnitts der Indexzahlen für das vorletzte Kalenderjahr vor der Preisanpassung gegenüber dem Durchschnitt der Indexzahlen für das letzte Kalenderjahr vor der Preisanpassung. Sofern der Verkäufer in einem Jahr von einer Preiserhöhung absieht, bleibt sein Recht auf künftige Preiserhöhungen unberührt. Unterbleibt eine Entgelterhöhung in einem oder mehreren aufeinanderfolgenden Jahren, kann bzw. können sie mit Wirkung zur nächsten vorgenommenen Entgelterhöhung nachgeholt werden, wobei in diesem Fall die Anpassung in jenem Ausmaß erfolgt, welche der Veränderung der für den Durchschnitt des Jahres vor der Entgelterhöhung verlautbarten Indexzahl zu der Indexzahl entspricht, die die Grundlage für die letzte durchgeführte Entgelterhöhung war.</li>
                                        <li>Preisanpassungen werden Verbrauchern zumindest zwei Monate vor dem Zeitpunkt ihres Inkrafttretens angeboten. Sofern der Verbraucher einer Preisanpassung gemäß Punkt 2.4 widerspricht, ist der Verkäufer bis zum Inkrafttreten der Preiserhöhung berechtigt, die Geschäftsbeziehung mit sofortiger Wirkung außerordentlich zu kündigen.</li>
                                    </ul>
                                </li>
                                <li>Zahlungsbedingungen
                                    <ul>
                                        <li>Sofern nichts anderes schriftlich vereinbart wurde, hat der Kunde das Entgelt vor Erbringung der Leistungen des Verkäufers vollständig und ohne Abzug zu bezahlen.</li>
                                        <li>Der Verkäufer übermittelt dem Kunden nach Vertragsabschluss eine Rechnung, die der Kunde mit einer der auf der Rechnung angeführten Zahlungsmittelvarianten zu bezahlen hat.</li>
                                        <li>Je nach dem vom Kunden gewählten Zahlungsintervall (einmalige Zahlung, jährliche Zahlung oder monatliche Zahlung), erfolgt die Zahlung des Kunden im Voraus. Der erstmalige Zugang zu den Leistungen des Verkäufers erfolgt nach abgeschlossener Bezahlung der ersten Abonnement Entgelte. </li>
                                        <li>Nach Ablauf der Mindestvertragsdauer muss der Kunde das monatliche Entgelt jeweils im Voraus an den Verkäufer bezahlen.</li>
                                    </ul>
                                </li>
                                <li>Laufzeit
                                    <ul>
                                        <li>Der Verkäufer schließt sämtliche Verträge mit einer Mindestvertragsdauer ab. Das bedeutet, dass der Vertrag vor Ablauf der Mindestvertragsdauer nur aus wichtigem Grund gekündigt werden kann. Eine ordentliche Kündigung ist erst nach Ablauf der Mindestvertragsdauer möglich.</li>
                                        <li>Die Mindestvertragsdauer beginnt mit dem Tag des Vertragsabschlusses gemäß Punkt 1.4.2 zu laufen.</li>
                                        <li>Mindestvertragsdauer bei Verbraucherverträgen
                                            <ul>
                                                <li>Der Verkäufer bietet Verbrauchern den Abschluss von Verträgen mit unterschiedlicher Mindestvertragsdauer an (12, 24 oder 36 Monate).</li>
                                                <li>Nach Ablauf der Mindestvertragsdauer besteht der Vertrag weiter und kann vom Verbraucher gemäß Punkt 8 ordentlich gekündigt werden.</li>
                                            </ul>
                                        </li>
                                        <li>Mindestvertragsdauer bei zweiseitigen unternehmensbezogenen Geschäften
                                            <ul>
                                                <li>Die Mindestvertragsdauer für Unternehmer beträgt 12, 24, 36, 48 oder 60 Monate</li>
                                                <li>Sofern der Unternehmer den Vertrag nicht spätestens drei Monate vor Ablauf der Mindestvertragsdauer kündigt, verlängert sich der Vertrag um einen Zeitraum von 12 Monaten.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Widerrufsrecht des Verbrauchers
                                    <p>Ist der Kunde Verbraucher im Sinne des KSchG hat er/sie das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</p>
                                    <p>Für den Widerruf muss uns der Kunde mit einer eindeutigen Erklärung (zB ein mit der Post versandter Brief, Telefax oder E-Mail) über seinen Entschluss, diesen Vertrag zu widerrufen, informieren. Der Rücktritt ist jedoch an keine bestimmte Form gebunden. Sie können das beigefügt Muster-Widerrufsformular verwenden, das jedoch nicht verpflichtend ist.</p>
                                    <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
                                </li>
                            </ul>

                            <ul>
                                <li>Muster-Widerrufsformular
                                    <p>Die Ausübung des Widerrufsrechts kann mittels folgenden Widerrufsformulars erfolgen (wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte folgendes Formular aus und senden Sie es zurück):<br /><br />
                                    An die spixnet gmbh<br />
                                    Am Anger 1<br />
                                    <br />
                                    7210 Mattersburg<br />
                                    E-Mail: office@spixnet.com<br />
                                    <br />
                                    Ich/wir erkläre/n hiermit, dass ich/wir von meinem/unserem Vertrag über die folgende Ware/Dienstleistung zurücktrete/zurücktreten: _________________________<br />
                                    Bestellt am /Erhalten am:___________<br />
                                    Name des Verbrauchers/der Verbraucher:____________________________________<br />
                                    Adresse des Verbrauchers/der Verbraucher:__________________________________<br />
                                    Unterschrift des Verbrauchers/der Verbraucher (nur, wenn dieses Formular in Papierform übermittelt wird): _________________________________________________<br />
                                    Datum:____________<br />

                                    </p>
                                </li>
                            </ul>

                            <ul>
                                <li><b>Folgen des Widerrufs:</b> Wenn der Verbraucher den Vertrag widerruft, haben wir diesem alle Zahlungen, die wir von ihm erhalten haben, unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über dessen Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das der Verbraucher bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, mit diesem wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden dem Verbraucher wegen dieser Rückzahlung Entgelte berechnet. </li>
                                <li>Nach Widerruf des Vertrags durch den Verbraucher werden die Leistungen durch den Verkäufer eingestellt.</li>
                                <li>Hat der Verbraucher, im Falle eines Vertrages über Dienstleistungen, verlangt, dass diese während der Widerrufsfrist beginnen sollen, so hat er uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem der Verbraucher uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichtet, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</li>

                                <li><b>Ausnahmen vom Rücktrittsrecht:</b>
                                    <p>Der Verbraucher hat gemäß § 18 Abs 1 FAGG kein Rücktrittsrecht beim Abschluss von Verträgen über:</p>
                                </li>
                            </ul>

                            <ul>
                                <li>Dienstleistungen, wenn der Verkäufer die Dienstleistung vollständig erbracht hat, wobei in jenen Fällen, in denen der Verbraucher nach dem Vertrag zu einer Zahlung verpflichtet ist, das Rücktrittsrecht nur entfällt, wenn überdies der Unternehmer mit der vorherigen ausdrücklichen Zustimmung des Verbrauchers mit der Vertragserfüllung begonnen hat und wenn der Verbraucher vor Beginn der Dienstleistungserbringung bestätigt hat, zur Kenntnis genommen zu haben, dass er sein Rücktrittsrecht mit vollständiger Vertragserfüllung verliert.</li>
                                <li>die Bereitstellung von digitalen Inhalten, die nicht auf einem körperlichen Datenträger geliefert werden sollen, wenn der Verkäufer mit der Vertragserfüllung begonnen hat, wobei in jenen Fällen, in denen der Verbraucher nach dem Vertrag zu einer Zahlung verpflichtet ist, das Rücktrittsrecht nur entfällt, wenn überdies</li>
                                <li>der Verbraucher dem Beginn der Vertragserfüllung vor Ablauf der Rücktrittsfrist ausdrücklich zugestimmt hat,</li>
                                <li>der Verbraucher bestätigt hat, zur Kenntnis genommen zu haben, dass er durch den vorzeitigen Beginn der Vertragserfüllung sein Rücktrittsrecht verliert, und</li>
                                <li>der Unternehmer dem Verbraucher eine Ausfertigung oder Bestätigung nach § 5 Abs. 2 oder § 7 Abs. 3 zur Verfügung gestellt hat</li>
                                <li>Ordentliche Kündigung bei Verbrauchern
                                    <ul>
                                        <li>Die Geschäftsbeziehung kann während der Mindestvertragsdauer gemäß Punkt 6.3.1 nur aus wichtigem Grund gekündigt werden.</li>
                                        <li>Nach Ablauf der Mindestvertragsvertragsdauer können der Verkäufer und der Verbraucher die gesamte Geschäftsbeziehung unter Einhaltung einer Frist von dreißig Tagen kündigen. Das Recht des Kunden, aufgrund jeglicher Änderung der AGB kostenlos zu kündigen, bleibt hiervon unberührt. Der Kunde kann bei einem Bezahlmodell die Leistungen bis zum Ende des Kündigungszeitraums nutzen.</li>
                                    </ul>
                                </li>
                                <li>Kündigung
                                    <ul>
                                        <li><b>Kündigung aus wichtigem Grund:</b> Der Verkäufer und der Kunde können die gesamte Geschäftsbeziehung oder einzelne Teile davon aus wichtigem Grund jederzeit ohne Einhaltung einer Kündigungsfrist mit sofortiger Wirkung kündigen. </li>
                                        <li>Gründe, welche den Verkäufer insbesondere berechtigen, die Geschäftsbeziehung zu kündigen:
                                            <p>Der Verkäufer behält sich das Recht vor, das Vertragsverhältnis mit dem Kunden aus wichtigem Grund mit sofortiger Wirkung und ohne weitere Mitteilung an den Kunden zu kündigen und das entsprechende Kundenkonto zu sperren, insbesondere, aber nicht ausschließlich, bei</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                            <ul>

                                <li>Zahlungsverzügen, die trotz einer angemessenen Frist ab Zugang einer Mahnung nicht behoben wurden;</li>
                                <li>Verwendung des Systems des Verkäufers zum Versenden von Spam;</li>
                                <li>Verwendung des Systems des Verkäufers für Penetrationstests, Hacking-Angriffe, Brute-Force Attacken oder vergleichbare Störversuche;</li>
                                <li>Strafrechtlich relevante Schädigung des Verkäufers oder anderer Kunden durch einen Kunden, einschließlich Kreditschädigung;</li>
                                <li>Nutzung des Systems des Verkäufers auf eine Weise, die dem Verkäufer Schäden, Nachteile oder Verluste zufügt und/oder eine Leistung des Verkäufers beeinträchtigt;</li>
                                <li>Jedem strafrechtlich relevanten Verhalten eines Kunden gegenüber dem Verkäufer, den Gesellschaftern, Geschäftsführern oder Mitarbeitern des Verkäufers oder gegenüber anderen Kunden.</li>
                                <li>Kundenverzug, Einstellung der Leistung
                                    <ul>
                                        <li>Der Kunde ist immer dann in Verzug (jeweils ein "<b>Kundenverzug</b>"), wenn er einen Betrag, den er dem Verkäufer schuldet, bei Fälligkeit nicht bezahlt, zum Beispiel, immer wenn die Zahlung, nicht zu dem Zeitpunkt der Fälligkeit auf dem Bankkonto des Verkäufers gutgeschrieben wird; bei Verbrauchern reicht es aus, wenn der Verbraucher den Überweisungsauftrag am Tag der Fälligkeit erteilt.</li>
                                        <li><b>Einstellung der Leistung:</b> Am fünften Tag des Kundenverzugs erfolgt eine Einstellung des Zugangs des in Verzug befindlichen Kunden zu kostenpflichtigen Leistungen des Verkäufers. Der Zugang des Kunden wird diesfalls vollständig gesperrt. </li>
                                        <li><b>Mahngebühren.</b> Im Falle eines vom Kunden verschuldeten Kundenverzugs kann der Verkäufer Mahngebühren erheben. Die Mahngebühren müssen in einem angemessenen Verhältnis zu dem in Verzug befindlichen Betrag stehen und erforderlich sein, um die angemessenen Kosten des Verkäufers für die Verwaltung des Kundenverzugs und die Durchsetzung seiner Rechte zu decken. An Mahngebühren werden mindestens EUR 5 und maximal EUR 15 eingehoben. Diese Mindestgebührenregelung kommt nicht zur Anwendung, wenn der Kunde nachweist, dass kein Schaden entstanden und daher keine oder geringere Verwaltungskosten oder Kosten für die Durchsetzung der Rechte des Verkäufers angefallen sind. </li>
                                    </ul>
                                </li>
                                <li>Haftung
                                    <ul>
                                        <li>Haftung gegenüber Verbrauchern: Für etwaige Schäden haftet der Verkäufer für sich und seine Erfüllungsgehilfen – gleich aus welchem Rechtsgrund einschließlich unerlaubter Handlung – nur, falls der Verkäufer oder seine Erfüllungsgehilfen eine vertragswesentliche Pflicht (Kardinalpflicht) schuldhaft in einer den Vertragszweck gefährdenden Weise verletzt haben oder der Schaden auf grobe Fahrlässigkeit oder Vorsatz des Verkäufers oder seiner Erfüllungsgehilfen zurückzuführen ist. Erfolgt die schuldhafte Verletzung einer vertragswesentlichen Pflicht (Kardinalpflicht) nicht in grob fahrlässiger oder vorsätzlicher Weise, so ist die Haftung durch den Verkäufer auf solch typische Schäden begrenzt, die für den Verkäufer bei Vertragsschluss vernünftiger Weise voraussehbar waren. Diese Haftungsbegrenzung gilt auch für den Schadensumfang. In den vorgenannten Fällen ist die Haftung für einen Vermögensschaden auf EUR 25.000 beschränkt. Die vorstehenden Haftungsbeschränkungen gelten nicht beim Fehlen zugesicherter Eigenschaften und einer Haftung des Verkäufers nach dem Produkthaftungsgesetz oder bei Personenschäden.</li>
                                    </ul>
                                </li>
                                <li>Datenschutz, Adressenänderung
                                    <ul>
                                        <li>Die uns vom Kunden im Rahmen der Geschäftsbeziehung bekannt gegebenen persönlichen Daten wie z.B. Name, Adresse, E-Mail, Telefonnummer sowie Lieferanschrift werden von uns gespeichert und verarbeitet. Wir benutzen die Daten im Rahmen des Artikel 6 Abs 1 lit b DSGVO, um die Bestellung auszuführen. Mit Aktivierung des Kästchens zur datenschutzrechtlichen Zustimmung, stimmt der Kunde ausdrücklich zu, dass die von ihm im Zuge des Vertragsabschlusses bekannt gegebenen personenbezogenen Daten (wie Name, Adresse, E-Mail, Telefonnummer sowie Lieferanschrift) auch zu eigenen Marketingzwecken und somit insbesondere für Zusendungen zu werblichen Zwecken per SMS und E-Mail gemäß Artikel 6 Abs 1 lit a DSGVO gespeichert und verarbeitet werden. Eine darüber hinausgehende Weitergabe personenbezogener Daten an Dritte findet nicht statt. Beide Zustimmungen können – auch getrennt voneinander – jederzeit (zB per Mail an office@spixnet.com) widerrufen werden. Unser Unternehmen kann im Rahmen der Bestimmungen der Artikel 28 bis 30 DSGVO andere Personen mit der Datenverarbeitung beauftragen. Weder durch unser Unternehmen, noch durch unsere Partner (Auftragsverarbeiter) werden Daten an Empfänger Übermittelt oder Überlassen, die ihren Sitz außerhalb des Europäischen Wirtschaftsraum haben. Wir sind zur Einhaltung der Datenschutzmaßnahmen nach der DSGVO verpflichtet.</li>
                                        <li>
                                        Der Kunde ist verpflichtet uns Änderungen seiner Wohn- bzw. Geschäftsadresse sowie Kontaktdaten während aufrechter Geschäftsbeziehung umgehend bekannt zu geben. Unterlässt er die Mitteilung, so gelten Erklärungen auch dann als zugegangen, falls sie an die von ihm zuletzt bekannt gegebene Adresse gesendet werden.
                                        </li>
                                    </ul>
                                </li>
                                <li>Gerichtsstand, Rechtswahl, Sonstiges
                                    <ul>
                                        <li>Ist unser Kunde Verbraucher und hat zum Zeitpunkt des Vertragsabschlusses in Österreich seinen Wohnsitz oder seinen gewöhnlichen Aufenthalt oder ist er im Inland beschäftigt, so kann für eine Klage gegen ihn nur die Zuständigkeit des Gerichtes begründet werden, in dessen Sprengel der Wohnsitz, der gewöhnliche Aufenthalt oder der Ort der Beschäftigung liegt. Ist der Kunde Verbraucher und zum Zeitpunkt des Vertragsabschlusses in einem anderen EU-Mitgliedsstaat wohnhaft oder und üben wir in dem Mitgliedstaat der EU, in dessen Hoheitsgebiet der Verbraucher seinen Wohnsitz hat, eine berufliche oder gewerbliche Tätigkeit aus oder richten eine solche auf irgend einem Wege auf diesen Mitgliedstaat oder auf mehrere Staaten, einschließlich dieses Mitgliedstaats, aus und fällt der Vertrag in den Bereich dieser Tätigkeit, so kann auch vor dem Gericht des Ortes, an dem der Verbraucher seinen Wohnsitz hat, die Klage des Verbrauchers erhoben werden; die Klage gegen den Verbraucher kann nur vor den Gerichten des Mitgliedstaats erhoben werden, in dessen Hoheitsgebiet der Verbraucher seinen Wohnsitz hat.</li>
                                        <li>Es gilt ausschließlich das materielle Recht der Republik Österreich unter Ausschluss der Verweisungsnormen und des UN Kaufrechts. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als das Recht ihres Wohnsitzstaates keine günstigere Rechtsposition einräumt.</li>
                                        <li>Sollten einzelne Regelungen dieser AGB ganz oder teilweise unwirksam sein, so berührt dies die Gültigkeit der übrigen Regelungen und der unter ihrer Zugrundelegung geschlossenen Verträge nicht. Die ganz oder teilweise unwirksame Regelung wird bei Verträgen mit Unternehmern durch eine Regelung ersetzt, die dem Sinn und Zweck der unwirksamen Regelung am nächsten kommt.</li>
                                    </ul>
                                </li>
                            </ul>

                            <p className="text-center"><b>ABSCHNITT II. - Unternehmergeschäft</b></p>
                            <p><b>Wird ein Vertrag gemäß 1.1 mit einem Unternehmer geschlossen, gelten zusätzlich zu den oben angeführten Bestimmungen und überdies die folgenden abweichenden Bestimmungen</b></p>

                            <ul>
                                <li>Gewährleistung
                                    <p>Gegenüber Unternehmern wird das Recht auf Gewährleistung insofern eingeschränkt, als der Verkäufer die weitere Mängelbehebung verweigern kann, wenn ihm infolge einer Vielzahl von Verbesserungsbegehren des Kunden ein unverhältnismäßiger Aufwand entstanden ist. In diesem Fall ist der Kunde auch nicht berechtigt, Preisminderung oder Wandlung zu verlangen. </p>
                                </li>
                            </ul>

                            <ul>
                                <li>Haftung
                                    <ul>
                                        <li>Wir haften für Schäden gegenüber Unternehmern nach den gesetzlichen Bestimmungen. Die Haftung für leicht fahrlässig zugefügte Schäden ist jedoch ausgeschlossen. Diese Haftungsbeschränkung gilt nicht für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit von Menschen und für Ansprüche nach dem Produkthaftungsgesetz. Kunden, die Unternehmer sind, haben das Vorliegen von Vorsatz oder grober Fahrlässigkeit zu beweisen und Schadenersatzansprüche innerhalb von einem Jahr ab Gefahrenübergang geltend zu machen.</li>
                                        <li>Ist der Kunde Unternehmer, so sind allfällige Regressforderungen im Sinne des § 12 Produkthaftungsgesetz ausgeschlossen, es sei denn, der Regressberechtigte weist nach, dass der Fehler in unserer Sphäre verursacht und zumindest grob fahrlässig verschuldet worden ist.</li>
                                    </ul>
                                </li>
                                <li>Zurückbehaltungsverbote und Aufrechnung
                                    <p>Gerechtfertigte Reklamationen berechtigen Kunden, die Unternehmer sind, nicht zur Zurückhaltung des Rechnungsbetrages. Die Aufrechnung von behaupteten Gegenforderungen gegen die Entgeltsforderung ist nicht zulässig, soweit wir dieser nicht ausdrücklich zustimmen.</p>
                                    <ul>
                                        <li>Ordentliche Kündigung bei Unternehmen
                                            <p>Sofern es gemäß Punkt 6.4.2 zu einer Vertragsverlängerung kommt, können der Kunde und der Verkäufer jeweils die Geschäftsbeziehung mittels Kündigung mit einer Frist von weiteren drei Monaten zum Ende des Verlängerungszeitraums beenden. Erfolgt keine rechtzeitige Kündigung, verlängert sich die Geschäftsbeziehung um weitere zwölf Monate.</p>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>Rechtswahl, Gerichtsstand
                                            <ul>
                                                <li>Es gilt österreichisches Recht unter Ausschluss der Verweisungsnormen und des UN-Kaufrechtes. Erfüllungsort ist Mattersburg. </li>
                                                <li>Für Streitigkeiten aus oder im Zusammenhang wird die ausschließliche Zuständigkeit des sachlich zuständigen Gerichts in Mattersburg vereinbart.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <p>Bitte beachten Sie aber, daß uns der Widerruf zugehen muß, um wirksam zu sein. Der Kunde kann dafür das hier beigefügte Muster-Widerrufsformular verwenden.</p>

                            <p>Rücktrittserklärung  /Widerrufserklärung<br/>
                            An die spixnet gmbh per email an office@spixnet.com<br/>
                            Hiermit widerrufe(n) ich/wir die von den abgeschlsosenen Vertrag über den Artikel ………, welche Ich / wir am ……. Bestellt / am … erhalten haben. <br/>Rückzahlung bitte über die genutzte Zahlungsvariante veranlassen.<br/>
                            </p>
                            <p>6.2 Die Rücktrittsfrist beträgt wie folgt:</p>
 
                            <p>6.2.1 Bei Kaufverträgen und sonstigen auf den entgeltlichen Erwerb einer Ware gerichteten Verträgen können sie binnen 14 Tage ab dem Tag an dem der Verbraucher sie oder ein von ihm ihnen benannter Dritter, der nicht der Beförderer ist, in den Besitz an der Ware bzw. der letzten Teilsendung bzw der zuletzt gelieferten Ware erlangt hat (§ 11 Abs 2 Z 2 lit a) bis c) FAGG).</p>
                            
                            <p>6.2.2 Bei Verträgen über die regelmäßige Lieferung von Waren über einen festgelegten Zeitraum hinweg beträgt die Frist 14 Tage von dem Tag, an dem der Verbraucher den Besitz an der zuerst gelieferten Ware erlangt;</p>
                            
                            <p>6.2.3 Bei Verträgen über die Lieferung von nicht auf einem körperlichen Datenträger gespeicherten digitalen Inhalten (wie zB Domains, E-Mail Dienst) – sofern nicht die Ausnahme vom Rücktrittsrechts gem Pkt 6.8.11 zur Anwendung kommt – beträgt die Frist 14 Tage ab dem Tag des Vertragsabschlusses. 6.2.4 Die Rücktrittsfrist nach Pkt 6.2.1 bis</p>
                            
                            <p>6.2.4 ist gewahrt, wenn die Rücktrittserklärung innerhalb der Frist abgesendet wird. Bitte beachten Sie aber, daß uns der Widerruf zugehen muß, um wirksam zu sein (das Risiko im Falle einer postalischen oder elektronischen Übermittlung (Fax, Email) liegt bei Ihnen).</p>
                            
                            <p>6.3 Sind wir unserer Informationspflicht nach § 4 Abs 1 Z 8 FAGG nicht nachgekommen, so verlängert sich die Rücktrittsfrist aus Punkt 8.2 um zwölf Monate. Kommen wir unseren Informationspflichten innerhalb dieser Frist nach, so endet die Rücktrittsfrist 14 Tage nach dem Zeitpunkt, zu dem der Verbraucher diese Information erhält.</p>
                            
                            <p>6.4 Wenn der Verbraucher den Vertrag widerruft, haben wir diesem alle Zahlungen, die wir von ihm erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass der Verbraucher eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt hat), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über dessen Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das der Verbraucher bei der ursprünglichen Transaktion eingesetzt hat, es sei denn, mit diesem wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden dem Verbraucher wegen dieser Rückzahlung Entgelte berechnet. Bei Kaufverträgen oder sonstigen auf den entgeltlichen Erwerb einer Ware gerichteten Verträgen können wir die Rückzahlung verweigern, bis wir entweder die Ware wieder zurückerhalten haben oder der Verbraucher einen Nachweis über die Rücksendung der Ware erbracht hat, sofern wir nicht angeboten haben, die Ware selbst abzuholen.</p>
                            
                            <p>6.5 Der Verbraucher hat die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem dieser uns
                            Lieferung unser Eigentum</p>
                            
                            <p>7.2 Der Kunde ist verpflichtet, die Ware während des Bestehens des Eigentumsvorbehalts pfleglich zu behandeln. Er hat uns unverzüglich von allen Zugriffen Dritter auf die Ware zu unterrichten, insbesondere von Zwangsvollstreckungsmaßnahmen, sowie von Beschädigungen oder der Vernichtung der Ware. Der Kunde hat uns bei Vorliegen</p>

                        </div>
                    </div>
                </div>
            </div>
    
    </div>
}

export default Agb;