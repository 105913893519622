import { Link } from 'react-router-dom';

function Blogentry(props){
    const newlink = props.date.replace(/-/g, '/');

    let image = "https://spixnet.com/blogadmin/images/" + props.image;
    let link = "/cybersecurity-blog/" + newlink + '/' + props.slug;

    return <div className="blogbox" key={props.index}>
        <Link to={link} onClick= {() => window.scrollTo(0, 0)}>
            <div className="imgbox" style={{ backgroundImage: `url("${image}")` }}></div>            
        </Link>
        <div className="contentbox">
            <h4>{props.category}</h4>
            <Link to={link} onClick= {() => window.scrollTo(0, 0)}><h2>{props.title}</h2></Link>
            <p>{props.description.substring(0, 90)}...</p>
            <Link to={link} onClick= {() => window.scrollTo(0, 0)}>Read More</Link>
        </div>
        <div className="date">
            <h6>{props.date}</h6>
        </div>
    </div>
}

export default Blogentry;