import { useEffect } from 'react';
function Datenschutz(props){
    useEffect(() =>{
        const interval = setInterval(() => {
            //props.func('Datenschutz');
        }, 2000);
        return () => clearInterval(interval);
    },[]);
    return  <div className="customPage">    
                <div className="container-fluid bggray">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">

                                <h2>Datenschutzerklärung</h2>
                                <p><b>1) Information über die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen </b></p>
                                <p>1.1 Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse. Im Folgenden informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert werden können.</p>                                
                                <p>1.2 Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist spixnet GmbH, am Anger 1, 7210 Mattersburg, Österreich, Tel.: 0720404445, E-Mail: office@spixnet.com. Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. </p>
                                <p>1.3 Der Verantwortliche hat einen Datenschutzbeauftragten bestellt, dieser ist wie folgt zu erreichen: „Manuel Löw-Beer“ </p>
                                <p>1.4 Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in Ihrer Browserzeile erkennen. 
                                </p>

                                <p><b>2) Datenerfassung beim Besuch unserer Website</b></p>
                                <p>Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:</p>

                                <ul>
                                    <li>Unsere besuchte Website </li>
                                    <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes </li>
                                    <li>Menge der gesendeten Daten in Byte </li>
                                    <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten </li>
                                    <li>Verwendeter Browser </li>
                                    <li>Verwendetes Betriebssystem </li>
                                    <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)

                                    </li>
                                </ul>

                                <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen. </p>

                                <p><b>3) Cookies</b></p>
                                <p>Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. </p>
                                <p>Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchführung des Vertrages oder gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestmöglichen Funktionalität der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs. Wir arbeiten unter Umständen mit Werbepartnern zusammen, die uns helfen, unser Internetangebot für Sie interessanter zu gestalten. Zu diesem Zweck werden für diesen Fall bei Ihrem Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit vorbenannten Werbepartnern zusammenarbeiten, werden Sie über den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen Informationen innerhalb der nachstehenden Absätze individuell und gesondert informiert. Bitte beachten Sie, dass Sie Ihren Browser so einstellen können, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen können. </p>
                                <p>Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern können.</p>
                                <p>Diese finden Sie für die jeweiligen Browser unter den folgenden Links: </p>

                                <ul>
                                    <li>Microsoft Edge: https://support.microsoft.com/de-de/microsoft-edge/</li>
                                    <li>temporäres-zulassen-von-cookies-und-website-daten-in-microsoft-edge-597f04f2-c0ce-f08c-7c2b-541086362bd2 </li>
                                    <li>Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen </li>
                                    <li>Chrome: http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647 Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac </li>
                                    <li>Opera: http://help.opera.com/Windows/10.20/de/cookies.html</li>
                                </ul>

                                <p>Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalität unserer Website eingeschränkt sein kann.</p>

                                <p><b>4) Kontaktaufnahme</b></p>

                                <p>4.1 - Eigenes Live-Chat-System </p>
                                <p>Auf dieser Website werden zum Zweck des Betreibens eines Live-Chat-Systems, das der Beantwortung von Live-Anfragen dient, Ihr mitgeteilter Chat-Name und Ihre mitgeteilten Chat-Inhalte als Daten erhoben und für den Verlauf des Chats gespeichert. Der Chat und Ihr angegebener Chat-Name werden ausschließlich im sog. RAM (Random-Access Memory) gespeichert und sofort gelöscht, sobald wir oder Sie die Chat-Konversation beendet haben, spätestens jedoch 2 Stunden nach der letzten Nachricht im Chat-Verlauf. Für den Betrieb der Chat-Funktion werden Cookies eingesetzt. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden. Die Cookies ermöglichen die Wiedererkennung des Internet-Browsers des Seitenbesuchers, um eine Unterscheidung der einzelnen Benutzer der Chat-Funktion unserer Internetseite zu gewährleisten. Sofern die so erhobenen Informationen einen Personenbezug aufweisen, erfolgt die Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an effektiver Kundenbetreuung und der statistischen Analyse des Nutzerverhaltens zu Optimierungszwecken. Um die Speicherung von Cookies zu vermeiden, können Sie Ihren Internetbrowser so einstellen, dass zukünftig keine Cookies mehr auf Ihrem Computer abgelegt werden können bzw. bereits abgelegte Cookies gelöscht werden. Das Abschalten sämtlicher Cookies kann jedoch dazu führen, dass die Chat-Funktion auf unserer Internetseite nicht mehr ausgeführt werden kann. </p>

                                <p>4.2 - Eigene Bewertungserinnerung (kein Versand durch ein Kundenbewertungssystem) </p>

                                <p>Wir verwenden Ihre E-Mailadresse zur einmaligen Erinnerung an die Abgabe einer Bewertung Ihrer Bestellung für das von uns verwendete Bewertungssystem, sofern Sie uns hierzu während oder nach Ihrer Bestellung Ihre ausdrückliche Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO erteilt haben. Sie können Ihre Einwilligung jederzeit durch eine Nachricht an den für die Datenverarbeitung Verantwortlichen widerrufen. </p>

                                <p>4.3 Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen. </p>

                                <p><b>5) Nutzung von Kundendaten zur Direktwerbung </b></p>
                                <p>5.1 Anmeldung zu unserem E-Mail-Newsletter</p>

                                <p>Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden wir Ihnen regelmäßig Informationen zu unseren Angeboten. Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mailadresse. Die Angabe weiterer Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen zu können. Für den Versand des Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst dann einen E-Mail Newsletter übermitteln werden, wenn Sie uns ausdrücklich bestätigt haben, dass Sie in den Empfang von Newsletter einwilligen. Wir schicken Ihnen dann eine Bestätigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines entsprechenden Links zu bestätigen, dass Sie künftig den Newsletter erhalten wollen. Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter speichern wir Ihre vom Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das Datum und die Uhrzeit der Anmeldung, um einen möglichen Missbrauch Ihrer E-Mail- Adresse zu einem späteren Zeitpunkt nachvollziehen zu können. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten werden ausschließlich für Zwecke der werblichen Ansprache im Wege des Newsletters benutzt. Sie können den Newsletter jederzeit über den dafür vorgesehenen Link im Newsletter oder durch entsprechende Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre E-Mailadresse unverzüglich in unserem Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung informieren.</p>

                                <p>5.2 Versand des E-Mail-Newsletters an Bestandskunden </p>
                                <p>Wenn Sie uns Ihre E-Mailadresse beim Kauf von Waren bzw. Dienstleistungen zur Verfügung gestellt haben, behalten wir uns vor, Ihnen regelmäßig Angebote zu ähnlichen Waren bzw. Dienstleistungen, wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden. Hierfür müssen wir gemäß § 7 Abs. 3 UWG keine gesonderte Einwilligung von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein auf Basis unseres berechtigten Interesses an personalisierter Direktwerbung gemäß Art. 6 Abs. 1 lit. f DSGVO. Haben Sie der Nutzung Ihrer E-Mailadresse zu diesem Zweck anfänglich widersprochen, findet ein Mailversand unsererseits nicht statt. Sie sind berechtigt, der Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Werbezweck jederzeit mit Wirkung für die Zukunft durch eine Mitteilung an den zu Beginn genannten Verantwortlichen zu widersprechen. Hierfür fallen für Sie lediglich Übermittlungskosten nach den Basistarifen an. Nach Eingang Ihres Widerspruchs wird die Nutzung Ihrer E-Mailadresse zu Werbezwecken unverzüglich eingestellt. </p>

                                <p>5.3 - Werbung per Briefpost </p>
                                <p>Auf Grundlage unseres berechtigten Interesses an personalisierter Direktwerbung behalten wir uns vor, Ihren Vor- und Nachnamen, Ihre Postanschrift und - soweit wir diese zusätzlichen Angaben im Rahmen der Vertragsbeziehung von Ihnen erhalten haben - Ihren Titel, akademischen Grad, Ihr Geburtsjahr und Ihre Berufs-, Branchen- oder Geschäftsbezeichnung gemäß Art. 6 Abs. 1 lit. f DSGVO zu speichern und für die Zusendung von interessanten Angeboten und Informationen zu unseren Produkten per Briefpost zu nutzen. Sie können der Speicherung und Nutzung Ihrer Daten zu diesem Zweck jederzeit durch eine entsprechende Nachricht an den Verantwortlichen widersprechen. </p>

                                <p><b>6) Webanalysedienste</b></p>

                                <p>Google (Universal) Analytics</p>

                                <p>Diese Website benutzt Google (Universal) Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“). Google (Universal) Analytics verwendet sog. „Cookies“, das sind Textdateien, die auf Ihrem Endgerät gespeichert werden und die eine Analyse Ihrer Nutzung der Website ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich der gekürzten IP-Adresse) werden in der Regel an einen Server von Google übertragen und dort gespeichert, hierbei kann es auch zu einer Übermittlung an die Server der Google LLC. in den USA kommen. </p>

                                <p>Diese Website verwendet Google (Universal) Analytics ausschließlich mit der Erweiterung „_anonymizeIp()“, die eine Anonymisierung der IP-Adresse durch Kürzung sicherstellt und eine direkte Personenbeziehbarkeit ausschließt. Durch die Erweiterung wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google LLC.in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen. Dabei wird die im Rahmen von Google (Universal) Analytics von Ihrem Browser übermittelte IP-Adresse nicht mit anderen Daten von Google zusammengeführt. Google Analytics ermöglicht über eine spezielle Funktion, die sog. „demografischen Merkmale“, darüber hinaus die Erstellung von Statistiken mit Aussagen über Alter, Geschlecht und Interessen der Seitenbesucher auf Basis einer Auswertung von interessenbezogener Werbung und unter Hinzuziehung von Drittanbieterinformationen. Dies erlaubt die Definition und Differenzierung von Nutzerkreisen der Webseite zum Zwecke der zielgruppenoptimierten Ausrichtung von Marketingmaßnahmen. Über die „demografischen Merkmale“ erfasste Datensätze können jedoch keiner bestimmten Person zugeordnet werden. </p>

                                <p>Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von Google Analytics-Cookies für das Auslesen von Informationen auf dem verwendeten Endgerät, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt haben. Ohne diese Einwilligungserteilung unterbleibt der Einsatz von Google Analytics während Ihres Seitenbesuchs. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, deaktivieren Sie diesen Dienst bitte in dem auf der Webseite bereitgestellten „Cookie-Consent-Tool“. </p>

                                <p>Wir haben mit Google für die Nutzung von Google Analytics einen Auftragsverarbeitungsvertrag abgeschlossen, mit dem Google verpflichtet wird, die Daten unserer Seitenbesucher zu schützen und sie nicht an Dritte weiter zu geben. Für die Übermittlung von Daten aus der EU in die USA beruft sich Google hierbei auf sog. Standarddatenschutzklauseln der Europäischen Kommission, welche die Einhaltung des europäischen Datenschutzniveaus in den USA gewährleisten sollen.</p>
                                <p>Weitere Hinweise zu Google (Universal) Analytics finden Sie hier: https://policies.google.com/privacy?hl=de&gl=de </p>

                                <p><b>7) Retargeting/ Remarketing/ Empfehlungswerbung</b></p>

                                <p>Google Ads Remarketing</p>

                                <p>Unsere Website nutzt die Funktionen von Google Ads Remarketing, hiermit werben wir für diese Website in den Google-Suchergebnissen, sowie auf Dritt-Websites. Anbieter ist die Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland (“Google”). Zu diesem Zweck setzt Google ein Cookie im Browser Ihres Endgeräts, welches automatisch mittels einer pseudonymen Cookie-ID und auf Grundlage der von Ihnen besuchten Seiten eine interessensbasierte Werbung ermöglicht. Eine darüberhinausgehende Datenverarbeitung findet nur statt, sofern Sie gegenüber Google zugestimmt haben, dass Ihr Internet– und App-Browserverlauf von Google mit ihrem Google-Konto verknüpft wird und Informationen aus ihrem Google-Konto zum Personalisieren von Anzeigen verwendet werden, die sie im Web betrachten. Sind sie in diesem Fall während des Seitenbesuchs unserer Webseite bei Google eingeloggt, verwendet Google Ihre Daten zusammen mit Google Analytics-Daten, um Zielgruppenlisten für geräteübergreifendes Remarketing zu erstellen und zu definieren. Dazu werden Ihre personenbezogenen Daten von Google vorübergehend mit Google Analytics-Daten verknüpft, um Zielgruppen zu bilden. Im Rahmen der Nutzung von Google Ads Remarketing kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen. </p>

                                <p>Alle oben beschriebenen Verarbeitungen, insbesondere das Setzen von Cookies für das Auslesen von Informationen auf dem verwendeten Endgerät, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt haben. Ohne diese Einwilligungserteilung unterbleibt der Einsatz von Google Ads Remarketingl während Ihres Seitenbesuchs. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, deaktivieren Sie diesen Dienst bitte in dem auf der Webseite bereitgestellten „Cookie-Consent-Tool“. </p>

                                <p>Sie können dem Setzen von Cookies durch Google Ads Remarketing darüber hinaus auch dauerhaft widersprechen, indem Sie das unter folgendem Link verfügbare Browser-Plug-in von Google herunterladen und installieren: https://support.google.com/ads/answer/7395996? </p>

                                <p>Weitergehende Informationen und die Datenschutzbestimmungen bezüglich Werbung und Google können Sie hier einsehen: https://www.google.com/policies/technologies/ads/ </p>

                                <p><b>8) Seitenfunktionalitäten</b></p>

                                <p>8.1 Verwendung von Youtube-Videos </p>

                                <p>Diese Website nutzt die Youtube-Einbettungsfunktion zur Anzeige und Wiedergabe von Videos des Anbieters „Youtube“, der zu der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“) gehört. </p>
                                <p>Hierbei wird der erweiterte Datenschutzmodus verwendet, der nach Anbieterangaben eine Speicherung von Nutzerinformationen erst bei Wiedergabe des/der Videos in Gang setzt. Wird die Wiedergabe eingebetteter Youtube-Videos gestartet, setzt der Anbieter „Youtube“ Cookies ein, um Informationen über das Nutzerverhalten zu sammeln. Hinweisen von „Youtube“ zufolge dienen diese unter anderem dazu, Videostatistiken zu erfassen, die Nutzerfreundlichkeit zu verbessern und missbräuchliche Handlungsweisen zu unterbinden.</p>

                                <p>Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet, wenn Sie ein Video anklicken. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen. Im Rahmen der Nutzung von Youtube kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen. </p>

                                <p>Unabhängig von einer Wiedergabe der eingebetteten Videos wird bei jedem Aufruf dieser Website eine Verbindung zum Google-Netzwerk aufgenommen, was ohne unseren Einfluss weitere Datenverarbeitungsvorgänge auslösen kann. </p>

                                <p>Alle oben beschriebenen Verarbeitungen, insbesondere das Auslesen von Informationen auf dem verwendeten Endgerät über das Tracking-Pixel, werden nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche Einwilligung erteilt haben. Ohne diese Einwilligungserteilung unterbleibt der Einsatz von Youtube-Videos während Ihres Seitenbesuchs. </p>

                                <p>Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, deaktivieren Sie diesen Dienst bitte in dem auf der Webseite bereitgestellten „Cookie-Consent-Tool“ über alternative, Ihnen auf der Website mitgeteilte Möglichkeiten. Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in den Youtube-Nutzungsbedingungen unter https://www.youtube.com/static?template=terms sowie in der Datenschutzerklärung von Google unter https://www.google.de/intl/de/policies/privacy </p>

                                <p>8.2 Google reCAPTCHA </p>

                                <p>Auf dieser Website verwenden wir die reCAPTCHA-Funktion von Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland („Google“). Diese Funktion dient vor allem zur Unterscheidung, ob eine Eingabe durch eine natürliche Person erfolgt oder missbräuchlich durch maschinelle und automatisierte Verarbeitung erfolgt. Der Dienst umfasst den Versand der IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google und erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Feststellung der individuellen Eigenverantwortung im Internet und der Vermeidung von Missbrauch und Spam. Im Rahmen der Nutzung von Google reCAPTCHA kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server der Google LLC. in den USA kommen.</p>

                                <p>Weiterführende Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google können Sie einsehen unter: https://www.google.com/intl/de/policies/privacy/
                                Soweit rechtlich erforderlich, haben wir zur vorstehend dargestellten Verarbeitung Ihrer Daten Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO eingeholt. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Um Ihren Widerruf auszuüben, befolgen Sie bitte die vorstehend geschilderte Möglichkeit zur Vornahme eines Widerspruchs. </p>

                                <p>Für die Übermittlung von Daten aus der EU in die USA beruft sich Google auf sog. Standarddatenschutzklauseln der Europäischen Kommission, welche die Einhaltung des europäischen Datenschutzniveaus in den USA gewährleisten sollen. 
                                </p>

                                <p><b>9) Tools und Sonstiges</b></p>

                                <p>Cookie-Consent-Tool</p>

                                <p>Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen für einwilligungspflichtige Cookies und cookie-basierte Anwendungen ein sog. „Cookie-Consent-Tool“. Das „Cookie-Consent-Tool“ wird Nutzern bei Seitenaufruf in Form einer interaktive Benutzeroberfläche angezeigt, auf welcher sich per Häkchensetzung Einwilligungen für bestimmte Cookies und/oder cookie-basierte Anwendungen erteilen lassen. Hierbei werden durch den Einsatz des Tools alle einwilligungspflichtigen Cookies/Dienste nur dann geladen, wenn der jeweilige Nutzer entsprechende Einwilligungen per Häkchensetzung erteilt. So wird sichergestellt, dass nur im Falle einer erteilten Einwilligung derartige Cookies auf dem jeweiligen Endgerät des Nutzers gesetzt werden. </p>

                                <p>Das Tool setzt technisch notwendige Cookies, um Ihre Cookie-Präferenzen zu speichern. Personenbezogene Nutzerdaten werden hierbei grundsätzlich nicht verarbeitet. </p>
                                <p>Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung oder Protokollierung von Cookie-Einstellungen doch zur Verarbeitung personenbezogener Daten (wie etwa der IP-Adresse), erfolgt diese gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einem rechtskonformen, nutzerspezifischen und nutzerfreundlichen Einwilligungsmanagement für Cookies und mithin an einer rechtskonformen Ausgestaltung unseres Internetauftritts.</p>

                                <p>Weitere Rechtsgrundlage für die Verarbeitung ist ferner Art. 6 Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der rechtlichen Verpflichtung, den Einsatz technisch nicht notwendiger Cookies von der jeweiligen Nutzereinwilligung abhängig zu machen. Weitere Informationen zum Betreiber und den Einstellungsmöglichkeiten des Cookie-Consent-Tools finden Sie direkt in der entsprechenden Benutzeroberfläche auf unserer Website. </p>

                                <p><b>10) Rechte des Betroffenen </b></p>

                                <p>10.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten die nachstehenden Betroffenenrechte (Auskunfts- und Interventionsrechte), wobei für die jeweiligen Ausübungsvoraussetzungen auf die angeführte Rechtsgrundlage verwiesen wird: </p>

                                <ul>
                                    <li>Auskunftsrecht gemäß Art. 15 DSGVO; </li>
                                    <li>Recht auf Berichtigung gemäß Art. 16 DSGVO; </li>
                                    <li>Recht auf Löschung gemäß Art. 17 DSGVO; </li>
                                    <li>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO; </li>
                                    <li>Recht auf Unterrichtung gemäß Art. 19 DSGVO; </li>
                                    <li>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO; </li>
                                    <li>Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO; </li>
                                    <li>Recht auf Beschwerde gemäß Art. 77 DSGVO. </li>
                                </ul>

                                <p>10.2 WIDERSPRUCHSRECHT</p>
                                <p>WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN. MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT. WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN. MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>

                                <p><b>11) Dauer der Speicherung personenbezogener Daten </b></p>

                                <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen). Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung widerruft. Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht. </p>

                                <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. </p>
                                <p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO ausübt. </p>

                                <p>Sofern sich aus den sonstigen Informationen dieser Erklärung über spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>

                                <p><i>Stand: 16. Jänner 2023</i></p>








                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export default Datenschutz;