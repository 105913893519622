import { useParams } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect } from 'react';
import Blogdetail from "./Blogdetail";
import Sidebar from "./Sidebar";
import { Helmet } from 'react-helmet';
import Mightlike from "./Mightlike";
import { useLocation } from "react-router-dom";

function Blogdetails(props){
    let {slug} = useParams();
    const location = useLocation()

    const url = "https://spixnet.com/blogadmin/api/blogdetail?SPIXNET-API-KEY=3690cec4aa3fe2f30573d1ea72ab7776&slug=" + slug;
    const [data, setData] = useState([]);
    const [userinfo, setUserinfo] = useState([]);

    const fetchInfo = async () => {        
        return await fetch(url).then((res) => res.json()).then((d) => setData(d))
    }

    const getIPinfo = async () => {
        const ipinfo = await fetch('https://geolocation-db.com/json/').catch(error => console.log('error', error));
        const data = await ipinfo.json();
        
        if(data.country_code != 'undefined'){
            setUserinfo(data);
        }
    }

    const makeRecord = () => {
            if(userinfo.country_code != null){               
                var myHeaders = new Headers();
                myHeaders.append("SPIXNET-API-KEY", "3690cec4aa3fe2f30573d1ea72ab7776");
                //myHeaders.append('Content-Type', 'application/json');
                var formdata = new FormData();
                formdata.append("country_code", userinfo.country_code);
                formdata.append("country_name", userinfo.country_name);
                formdata.append("city", userinfo.city);
                formdata.append("postal", userinfo.postal);
                formdata.append("latitude", userinfo.latitude);
                formdata.append("longitude", userinfo.longitude);
                formdata.append("IP", userinfo.IPv4);
                formdata.append("slug", slug);
                
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata
                };


                fetch("https://spixnet.com/blogadmin/api/blogentry", requestOptions)
                    .then(response => response.text())
                    .then(result => {})
                    .catch(error => console.log('error', error));
            }
    }
    
    useEffect(() => {
        fetchInfo();             
    }, [location])
    
    useEffect(() => {
        getIPinfo();  
        const interval = setInterval(() => {
            props.func(slug);
        }, 2000);
        return () => clearInterval(interval);
    }, [location]);

    /*useLayoutEffect(()=>{
        clearInterval(intervalCall);
    })*/

    useEffect(()=>{
        makeRecord();
    })


    return <div className="customPage blogdetail">
                <Helmet>
                   <title>{"Spixnet - " + data.title}</title>
                   <meta property='og:image' content={"https://spixnet.com/blogadmin/images/" + data.image} />
                </Helmet>  
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-9">
                            
                            {data.title ? (
                               <Blogdetail 
                                image={data.image}
                                title={data.title}
                                category={data.category}
                                description={data.description}
                                date={data.created_date}
                                categories={data.categories}
                                tags={data.tags}
                                counter={data.counter}
                               />
                            ) : (
                                <div></div>
                            )}


                            {data.mightLike ? (
                            <div className="mightLike">
                                <h2>You might also like</h2>
                                <Mightlike 
                                    list={data.mightLike}
                                />
                            </div>
                            ) : (
                                <div></div>
                            )}
                            </div>
                            <div className="col-xl-3 col-lg-3 sidebar">
                               <Sidebar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export default Blogdetails;
