import React, { useState, useEffect } from 'react';
import Blogentry from './Blogentry';
import { Link } from 'react-router-dom';



function Blog(){
    const url = "https://spixnet.com/blogadmin/api/blog?SPIXNET-API-KEY=3690cec4aa3fe2f30573d1ea72ab7776";
    const [data, setData] = useState(null);

    const fetchInfo = () => {
        
     return fetch(url) 
            .then((res) => res.json()) 
            .then((d) => setData(d))
    }
    
    useEffect(() => {
        fetchInfo();
    }, [])

    if (data === null) {
        return <div className="container-fluid sliderBox" id="blogs">
                    <div className="">
                        <div className="row">
                            <div className="col">
                                <div className='loading'>
                                    <img src='https://spixnet.com/images/loading2.gif' alt='Loading..' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
    }

    return <div className="container-fluid sliderBox" id="blogs">
            <div className="">
                <div className="row">
                    <div className="col">
                        <h2>Cybersecurity News</h2>
                        <div id="carouselExampleInterval2" className="carousel carousel-dark slide" data-bs-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active" data-bs-interval="10000" key="1">
                                    <div className="slidercontent2">
                                    {data.map((dataObj, index) => {
                                        if(index >= 0 && index <=2){   
                                            return (
                                            <Blogentry 
                                                title={dataObj.title} 
                                                category= {dataObj.category}
                                                slug = {dataObj.slug}
                                                date= {dataObj.created_date}
                                                image={dataObj.image}
                                                description={dataObj.bdescription}
                                                index={index}
                                                />
                                            )
                                        }
                                    
                                        })}
                                    </div>
                                </div>
                                <div className="carousel-item" data-bs-interval="2000" key="2">
                                    <div className="slidercontent2">
                                    {data.map((dataObj, index) => {
                                        if(index >= 3 && index <=5){
                                            return (
                                            <Blogentry 
                                                title={dataObj.title} 
                                                category= {dataObj.category}
                                                slug = {dataObj.slug}
                                                date= {dataObj.created_date}
                                                image={dataObj.image}
                                                description={dataObj.bdescription}
                                                index={index}
                                                />
                                            )
                                        }                                
                                    })}
                                    </div>
                                </div>
                                <div className="carousel-item" key="3">
                                    <div className="slidercontent2">
                                    {data.map((dataObj, index) => {
                                        if(index >= 6 && index <=8){
                                            return (
                                            <Blogentry 
                                                title={dataObj.title} 
                                                category= {dataObj.category}
                                                slug = {dataObj.slug}
                                                date= {dataObj.created_date}
                                                image={dataObj.image}
                                                description={dataObj.bdescription}
                                                index={index}
                                                />
                                            )
                                        }                                
                                    })}
                                    </div>
                                </div>

                                <div className="carousel-item" key="4">
                                    <div className="slidercontent2">
                                    {data.map((dataObj, index) => {
                                        if(index >= 9 && index <=11){
                                            return (
                                            <Blogentry 
                                                title={dataObj.title} 
                                                category= {dataObj.category}
                                                slug = {dataObj.slug}
                                                date= {dataObj.created_date}
                                                image={dataObj.image}
                                                description={dataObj.bdescription}
                                                index={index}
                                                />
                                            )
                                        }                                
                                    })}
                                    </div>
                                </div>
                                
                                <div className="carousel-item" key="5">
                                    <div className="slidercontent2">
                                    {data.map((dataObj, index) => {
                                        if(index >= 12 && index <=14){
                                            return (
                                            <Blogentry 
                                                title={dataObj.title} 
                                                category= {dataObj.category}
                                                slug = {dataObj.slug}
                                                date= {dataObj.created_date}
                                                image={dataObj.image}
                                                description={dataObj.bdescription}
                                                index={index}
                                                />
                                            )
                                        }                                
                                    })}
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval2"
                                data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval2"
                                data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='more-news'>
                <Link to="/cybersecurity-blog">More News</Link>
            </div>
        </div>;
}

export default Blog;