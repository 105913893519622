import { Parser } from "html-to-react";
import { Link } from "react-router-dom";

function Blogdetail(props){
    let image = "https://spixnet.com/blogadmin/images/" + props.image;
    return <div className="blog-detail">
                {props.image != '' ? <img src={image} alt={props.title}/>: ""}
                <h1>{props.title}</h1>
                <div className="category">
                    <p>{props.date} | </p>
                    <ul className="categories">
                        {props.categories.map((cat, index)=>(
                            <li key={index}>
                                <Link to={"/cybersecurity-blog/category/"+cat.slug}>
                                {cat.title} 
                                </Link>
                            </li>  
                        ))}
                    </ul>
                </div>
                <div className="blog-description">{Parser().parse(props.description)}</div>
                <div className="counter">
                    <img src="/images/counter.png" />
                    <p>Post Views: {props.counter}</p>
                </div>
                <div className="tag">
                    <p>Tags</p>
                    <ul className="tags">
                        {props.tags.map((tag, index)=>(
                            <li key={index}>
                                <Link to={"/cybersecurity-blog/tag/"+tag.slug}>
                                {tag.title} 
                                </Link>
                            </li>  
                        ))}
                    </ul>
                </div>

            </div>
}

export default Blogdetail;