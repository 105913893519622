import SignatureCanvas from 'react-signature-canvas';
import { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';


export default function Payment(){
    const [sigPad, setSigPad] = useState([]);
    function clear (){
        sigPad.clear();
        document.getElementById('signature').value = '';
    }

    function end(){
      var img = sigPad.getTrimmedCanvas().toDataURL('image/png');
      document.getElementById('signature').value = img;
    }

    function showHideIBAN(e){
        /*if(e.target.value == "Monatlich"){
            document.getElementById('iban').style.display = "block";
        }else{
            document.getElementById('iban').style.display = "none";
        }*/
    }

    function isMobile() {
        if ('maxTouchPoints' in navigator) return navigator.maxTouchPoints > 0;
    
        const mQ = matchMedia?.('(pointer:coarse)');
        if (mQ?.media === '(pointer:coarse)') return !!mQ.matches;
        
        if ('orientation' in window) return true;
        
        return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(navigator.userAgent) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(navigator.userAgent);
      }

    function onSubmit(e){
        let inputs = {};

        e.preventDefault();
        let error = false;
        if(e.target.name.value == ""){
            error =true;
            document.getElementById(e.target.name.id).classList.add("error");
        }else{
            inputs['name'] = e.target.name.value;
            document.getElementById(e.target.name.id).classList.remove("error");
        }
        if(e.target.email.value == ""){
            error =true;
            document.getElementById(e.target.email.id).classList.add("error");
        }else{
            inputs['email'] = e.target.email.value;
            document.getElementById(e.target.email.id).classList.remove("error");
        }
        if(e.target.iban.value == ""){
            error = true;
            document.getElementById(e.target.iban.id).classList.add("error");
        }else{
            inputs['iban'] = e.target.iban.value;
            document.getElementById(e.target.iban.id).classList.remove("error");   
        }

        if(e.target.signature.value == ""){
            error =true;
            document.getElementById('sigCanvas').classList.add("error");
        }else{
            inputs['signature'] = e.target.signature.value;
            document.getElementById('sigCanvas').classList.remove("error");
        }

        inputs['paymentType'] = e.target.paymentType.value;
        
        if(error === false){
            fetch('https://spixnet.com/sendEmail.php', {
                        method: 'POST', 
                        body: JSON.stringify(inputs),
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                        },
                        mode: 'no-cors'
                    }).then(function(response) {
                        console.log(response)
                    }).then(function(muutuja){
                        console.log(muutuja)
                     });

                     document.getElementById('thankyou2').style.display = 'block';
            setTimeout(() => {
                document.getElementById("name").value = "";
                document.getElementById("email").value = "";
                document.getElementById("iban").value = "";
                document.getElementById("signature").value = "";
                sigPad.clear();
                document.getElementById('thankyou2').style.display = 'none';
            }, 2000);
        }
        
    }
    let mobileVar = {width: 310, height: 200,className: 'sigCanvas', id: 'sigCanvas'}
    let desktopVar = {className: 'sigCanvas', id: 'sigCanvas'}

    return <>
            <form onSubmit={onSubmit} >
                <div className="container-fluid bggray vh-100 p-0 m-0" id=''>
                    <div className="container-fluid m-0 pt-0 pb-0">
                        <div className="row align-items-top bggray">
                            <div className="col-xl-6 col-lg-6 text-left paymentbox  p-0 m-0">
                                <img src="images/Spixnet-Schriftzug_dark-blue.webp" className="paymentlogo"/>
                                <h2 className="mt-5">SEPA Mandat</h2>
                                <input type="text" className="payInput" name="name" id="name" placeholder="Name"/>
                                <input type="text" className="payInput" name="email" id="email" placeholder="Email-Adresse" />
                                <div className="row payradio mb-3">
                                    <div className="col-lg-6">
                                        <input type="radio" name="paymentType" id="Jahrlich" onChange={showHideIBAN} defaultChecked={true} value="Jährlich EUR 110,00" className="paymentType"/>
                                        <label htmlFor="Jahrlich">Jährlich EUR 110,00</label>
                                    </div>
                                    <div className="col-lg-6">
                                        <input type="radio" name="paymentType" id="Monatlich" onChange={showHideIBAN} value="Monatlich EUR 10,00" className="paymentType"/>
                                        <label htmlFor="Monatlich">Monatlich EUR 10,00</label>
                                    </div>
                                </div>
                                <input type="text" placeholder="IBAN hier eingeben" name="iban" id="iban" className="payInput" />
                                <div>
                                    <h3 className='payLabel'>Unterschrift</h3>
                                </div>
                                <div className='signaturepad' id='signaturepad'>
                                    <SignatureCanvas penColor='black' onEnd={end} canvasProps={isMobile()? mobileVar:desktopVar} ref={(ref) => { setSigPad(ref); }} />
                                    <button className="clear" type="button" onClick={clear}>X</button>
                                </div>                    
                                <input type='hidden' name='signature' id='signature' />
                                <input type='submit' className='paySubmit' value="Absenden" />

                                <div className="col-lg-12 thankyou2" id='thankyou2'>
                                    Form submitted successfully! thank you!
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 text-center prCol p-0 m-0">
                                <img src="images/payment.webp" alt='Spixnet'/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </>
}