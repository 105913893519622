import { useEffect } from 'react';
function Impressum(props){

    useEffect(() =>{
        const interval = setInterval(() => {
            //props.func('Impressum');
        }, 2000);
        return () => clearInterval(interval);
    },[]);


    return  <div className="customPage">    
                <div className="container-fluid bggray">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <h2>Impressum</h2>
                                <p><b>Informations- und Offenlegungspflicht gemäß § 24 MedienG und § 5 ECG</b></p>

                                <p><u>Medieninhaberin und Herausgeberin:</u></p>
                                <p>spixnet gmbh<br/>
                                Am Anger 1<br/>
                                7210 Mattersburg<br/>
                                Österreich</p>

                                <p>Rechtsform: Gesellschaft mit beschränkter Haftung</p>
                                <p>UID: ATU78155112</p>
                                <p>Firmenbuchnummer: FN 579120y<br/>
                                Gericht: Landesgericht Eisenstadt, Österreich<br/>
                                Aufsichtsbehörde: Wirtschaftskammer Österreich<br/>
                                Gewerbe:<br/>
                                IT Dienstleistungen, insbesondere im Bereich IT-Security und Anti-Ransomware.<br/>
                                Cloud Service Provider und Software as a Service.</p>

                                <p><u>Haftung:</u></p>
                                <p>Sämtliche Texte auf der Website wurden entsprechend der vollen Sorgfaltspflicht geprüft und werden in regelmäßigen Abständen sorgfältig geprüft. Ungeachtet dessen kann keine Garantie für Richtigkeit, und Aktualität der Angaben übernommen werden.</p>

                                <p><u>Datenschutz:</u></p>
                                <p>Die Website befindet sich auf einem Server in einem Rechenzentrumsverbund der Interxion Gesellschaften Österreich und Deutschland Gmbh; dort werden die üblichen Auswertungen über den Besucherverkehr anhand der IP-Adressen ohne Personenbezug getätigt. Eine Datenweitergabe findet nicht statt und dienen vollanonymisiert ausschließlich statistischen Zwecken.</p>

                                <p><u>Immaterialgüter:</u></p>
                                <p>Sämtlicher Inhalt dieser Website betreffend Bilder, bildhafter Darstellungen und Texte sind urheberrechtlich geschützt. Eine Verwendung ist nur mit ausdrücklicher vorheriger schriftlicher Genehmigung zulässig.</p>

                                <p><u>Verweise:</u></p>
                                <p>Direkte und indirekte Seitenverweise von und zu dieser Website sind Teil der medialen Vertretung der Sache und erwünscht. Set-Framing ist nicht erlaubt.</p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export default Impressum;