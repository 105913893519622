import { useState, useEffect } from 'react';
import Blog from './Blog';
function Content(props){

    useEffect(() =>{
        const interval = setInterval(() => {
            //props.func('Home');
        }, 2000);
        return () => clearInterval(interval);        
    },[]);

    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
       const name = event.target.name;
       const value = event.target.value;
       setInputs(values => ({...values, [name]: value}))
    }

    const [inputs2, setInputs2] = useState({});
    const handleChange2 = (event) => {
       const name = event.target.name;
       const value = event.target.value;
       setInputs2(values => ({...values, [name]: value}))
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
         fetch('https://spixnet.com/data.php', {  // Enter your IP address here

                method: 'POST', 
                body: JSON.stringify(inputs),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                 },
                 mode: 'no-cors'
            }).then(function(response) {                
                console.log(response)
            }).then(function(muutuja){
                console.log(muutuja)
            });
            setInputs({});
            document.getElementById('thankyou2').style.display = 'block';
            setTimeout(() => {
                document.getElementById('thankyou2').style.display = 'none';
            }, 2000);
      }
      const handleSubmit2 = (event) => {
        event.preventDefault();
         fetch('https://spixnet.com/data.php', {  // Enter your IP address here

                method: 'POST', 
                body: JSON.stringify(inputs2),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                 },
                 mode: 'no-cors'
            }).then(function(response) {
                
                console.log(response)
            }).then(function(muutuja){
                console.log(muutuja)
            });
            setInputs2({});
            document.getElementById('thankyou').style.display = 'block';
            setTimeout(() => {
                document.getElementById('thankyou').style.display = 'none';
            }, 2000);
      }


    return <div>
    <div className="container-fluid bggray banner" id='top'>
        <div className="container">
            <div className="row align-items-center slider">
                <div className="col-xl-5 col-lg-6 text-center p-md-5 p-xs">
                    <h1>The <span className="smart">smart</span> Encryption</h1>
                    <p>IT-Security has never been that fast & easy.</p>
                    <div className="btnGroup">
                        <a href='#contact2'>
                             <button type="button" className="align-middle">
                                <img src='images/global-network (1).webp' alt='globe'/>
                                <div>Get into a great <span>Partnership</span></div>
                            </button>
                        </a>
                        <a href='#contact'>
                            <button type="button" className="align-middle">
                                <img src='images/mail.webp' alt='mail' />
                                <div>Become a <span>Customer</span></div>
                            </button>
                        </a>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-6 text-center rightcol">
                    <img src="images/traveler-metro-using-phone2.webp" alt='Spixnet'/>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid bgblue" id="solutions">
        <div className="container">
            <div className="row pt-5 pb-5">
                <div className="col-lg-4">
                    <div className="servicebox">
                        <img src="images/secure-2.webp"  alt='Spixnet'/>
                        <h2>Without Installation or Migration.</h2>
                        <p>
                            spixnet is a cloud solution service, without any software
                            installation and requires no installation and no migration. No
                            downtime.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="servicebox">
                        <img src="images/domain-2.webp"  alt='Spixnet'/>
                        <h2>Just swap three entires.</h2>
                        <p>
                            Chnange the Sender Policy Framework (SPF) and Mail Exchange (MX)
                            entries in your Domain Name Server (DNS) as well as the Mail
                            Transfer Unit (MTU).
                        </p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="servicebox">
                        <img src="images/touch-2.webp"  alt='Spixnet'/>
                        <h2>Get real end2end encryption service.</h2>
                        <p>
                            Tell us who should now send encrypted (via DB, API or interface)
                            and let's get started. From now on, every email is sMIME
                            encrypted and digitally signed.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid form" id='contact2'>
        <div className="row">
            <div className="col-lg-5">
                <img src="images/spixnet-contact.webp" alt="Call me" className="callme" />
            </div>
            <div className="col-lg-7">
                <div>
                    <h2>Let's connect and get a Quote</h2>
                    <form id="contactForm2" className="contactForm" onSubmit={handleSubmit2}>
                        <input type="text" name="name" placeholder="Name" required value={inputs2.name || ""} 
        onChange={handleChange2} />
                        <input type="email" name="email" placeholder="E-mail Address" required value={inputs2.email || ""} 
        onChange={handleChange2}/>
                        <input type="text" name="phone" placeholder="Phone Number" required value={inputs2.phone || ""} 
        onChange={handleChange2}/>
                        <input type="text" name="message" placeholder="Message" required value={inputs2.message || ""} 
        onChange={handleChange2}/>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <div className="col-lg-12" className="thankyou" id='thankyou'>
                Form submitted successfully! thank you!
            </div>
        </div>
    </div>

    <div className="container-fluid bggray pedtp">
        <div className="container">
            <div className="row align-items-center p-md-5">
                <div className="col-xl-4 col-lg-4 text-center">
                    <div className="solution">
                        <img src="images/gerichts-sv-logo.webp"  alt='Spixnet'/>
                        <div className="scontent">
                            <p>
                                The generally sworn and <b>court-certified forensic expert</b>
                            </p>
                            <p>
                                It is very high quality programmed end-to-end encryption
                                system, that runs fully automatically during operations. All
                                measures have been taken to ensure the maximum available
                                levels of safety
                            </p>
                            <h4>
                                Prof. Horst Eidenberger<br />Technical University Vienna
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 text-center">
                    <div className="solution">
                        <img src="images/allianz.webp"  alt='Spixnet' className='allianz'/>
                        <div className="scontent">
                            <p>
                                The German Federal Office for information Security (<b>BSI</b>) <b>confirmed</b>:
                            </p>
                            <p>
                                The inclusion of spixnet gmbh in the Alliance for Cyber Security as well as a participant in the so-called cyper security network of the Federal Republic of Germany
                            <br /><br /></p>
                            <h4>
                                As the first non-German company
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 text-center">
                    <div className="solution">
                        <img src="images/ISO_27001_farbe_single (1).webp"  alt='Spixnet'/>
                        <div className="scontent">
                            <p>
                            <b>TUV SUD AG</b> is confirmed as one of the largest auditorganizations in the world.
                            </p>
                            <p>
                            As part of a lead audit in accordance with ISO 27001 information security of all spixnet gmbh processes, their quality, stability and full functionality.
                            </p>
                            <h4>
                             spixnet gmbh has been fully certified.
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-lg-12 text-center">
                    <a href="#contact"><button type="button" className="ssrn">secure start right now ></button></a>
                </div>
            </div>
        </div>
    </div>


    
    <div className="container-fluid bggray pedtp sobuss">
        <div className="container">
            <div className="row align-items-center p-md-5">
                <div className="col-xl-12 col-lg-12">
                    <h2>Solutions For Your Business</h2>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="sbusiness">
                        <h3>Email Encryption</h3>
                        <p>
                            Easily encrypt E-mails across devices and platforms without
                            installation or migration. Suitable for personal use, resale as
                            a reseller or user in MNO/MVNO email technologies.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="sbusiness">
                        <h3>Encrypted E-Mail Storage</h3>
                        <p>
                        Full encryption start at Layer 3 with no loss of performance and no loss of comfort for you and your Users and Customers. Device or Platform does not matter any more for you.
                        </p>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6">
                    <div className="sbusiness">
                        <h3>Domain Management & DNS</h3>
                        <p>
                        Fully white label-capable user interface for controlling all modular products from encrypted emails to storage space to domain management.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="sbusiness">
                        <h3>Certificates SSL, sMIME & More</h3>
                        <p>
                        Does not matter whether free SSL annual certificates inexpensive *wildcards for your domain(s), brand certificates or sMIME; With us you will find what you and yours users and your customers are looking for.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="sbusiness">
                        <h3>Fully Encrypted E-Mail Service (Q1.2024)</h3>
                        <p>
                        Fully white label-capable user interface for controlling all modular solutions per User start from encrypted emails to encrypted E-Mail & Cloud storage space to domain management etc.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    <div className="sbusiness">
                        <h3>Full Encrypted Cloud Storage (Q2.2024)</h3>
                        <p>
                        Full encryption start at Layer 3 with no loss of performance and no loss of comfort for you and your Users and Customers. Available as white label interface or NextCloud Container Solution.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <Blog />


    
    <div className="container-fluid sliderBox" id="customers">
        <div className="">
            <div className="row">
                <div className="col">
                    <div id="carouselExampleInterval" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="slidercontent">
                                    <div>
                                        <img src="images/markus-mateyka.webp"  className="sliderImg" alt='Spixnet'/>
                                    </div>
                                    <div className="description">
                                        <p>As a pharmaceutical company, protecting our customer and company data is
                                            particularly important to us. Spixnet was active for all employees in under
                                            15 minutes and works excellently.</p>
                                        <div className='sliderInfo'>
                                            <h3>Mag. Markus Mateyka</h3>
                                            <p>General Manager<br/>haemo pharma gmbh</p>
                                            <img src='images/haemo.webp' alt="haemo pharma gmbh" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item" data-bs-interval="2000">
                                <div className="slidercontent">
                                    <div>
                                        <img src="images/slider2.webp" className="sliderImg"  alt='Spixnet'/>
                                    </div>
                                    <div className="description">
                                        <p>We have trusted spixnet since the very beginning and are proud to have a partnership with such a successful startup from Burgenland.</p>
                                        <div className='sliderInfo'>
                                            <h3>Mag. Michael Gerbavsits</h3>
                                            <p>CEO<br/>Wirtschaftsagentur Burgenland</p>
                                            <img src='images/Herunterladen (3).webp' alt='Wirtschaftsagentur Burgenland'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="slidercontent">
                                    <div>
                                        <img src="images/slider3.webp"  className="sliderImg" alt='Spixnet'/>
                                    </div>
                                    <div className="description">
                                        <p>We currently look after the majority of municipalities in Burgenland, as well as the state parliament. As a Platinum Reseller, we are enthusiastic and convinced that we can actively protect each of our customer with spixnet.</p>
                                        <div className='sliderInfo'>
                                            <h3>Christian Dick</h3>
                                            <p>CEO<br/>bit studio gmbh</p>
                                            <img src='images/bitstudio.webp' alt='bit studio gmbh' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="slidercontent">
                                    <div>
                                        <img src="images/slider4.webp"  className="sliderImg" alt='Spixnet'/>
                                    </div>
                                    <div className="description">
                                        <p>
                                        As part of the EY Scale-up Initiative, we work with the strongest and most promising growth companies and want to bring the hidden champions of the lane to the fore. Spixnet clearly falls into this category and stands out for its technological innovation, easy-to-implement solutions and high impact on a widespread problem.
                                        </p>
                                        <div className='sliderInfo'>
                                            <h3>Mag. Florian Haas, MMSc MA</h3>
                                            <img src='images/eyscale.webp' alt='Ernst & Young'/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <div className="slidercontent">
                                    <div>
                                        <img src="images/slider5.webp"  className="sliderImg" alt='Spixnet'/>
                                    </div>
                                    <div className="description">
                                        <p>
                                        This is an easy and effortless to sell service generating significant recurrent revenue for MNOs and MVNOs. It benefits from large scale growth with established and aspiring communication service providers - helping to add profitibality.
                                        </p>
                                        <div className='sliderInfo'>
                                            <h3>Peter Nussbaumer </h3>
                                            <p>General Manager<br/>members mobile</p>
                                            <img src='images/mmobile.webp' alt='members mobile' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval"
                            data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-fluid bggray">
        <div className="container">
            <div className="row align-items-center slider knowledge">
                <div className="col-xl-6 col-lg-6 text-center p-md-5 p-xs">
                    <h2>Knowledge - the only good that increase when shared.</h2>
                    <p>That's why we're always invited to trade fairs and conferences on the topic of information security. Most recently at the trade <b>association's security summit</b> and in a <b>TV discussion</b> on Cafe-Puls.</p>
                    <img src='images/btm2.webp' className='btm2'  alt='Spixnet'/>
                </div>
                <div className="col-xl-6 col-lg-6 text-center rightcol2">
                    <img src="images/easyfete.webp"  alt='Spixnet'/>
                </div>
            </div>
        </div>
    </div>


    
    <div className="container-fluid form" id="contact">
        <div className="row">
            <div className="col-lg-5">
                <img src="images/spixnet-get-informations.webp" alt="Call me" className="callme" />
            </div>
            <div className="col-lg-7">
                <div>
                    <h2>Contact us for more information</h2>
                    <form id="contactForm" className="contactForm" onSubmit={handleSubmit}>
                        <input type="text" name="name" placeholder="Name" required value={inputs.name || ""} 
        onChange={handleChange} />
                        <input type="email" name="email" placeholder="E-mail Address" required value={inputs.email || ""} 
        onChange={handleChange}/>
                        <input type="text" name="phone" placeholder="Phone Number" required value={inputs.phone || ""} 
        onChange={handleChange}/>
                        <input type="text" name="message" placeholder="Message" required value={inputs.message || ""} 
        onChange={handleChange}/>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            
            <div className="col-lg-12" className="thankyou2" id='thankyou2'>
                Form submitted successfully! thank you!
            </div>
        </div>
    </div>

    </div>
}

export default Content;