import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Header() {

    const [show, setShow] = useState(true);

    function checkPage(){
        if(window.location.pathname == "/payment"){
            setShow(false)
        }
    }
    
    useEffect(() => {
        checkPage();
      }, [])

    return <nav className="navbar navbar-expand-lg bg-body-tertiary" id={show?"ShowBlock":"HideBlock"}>
    <div className="container">
         <Link to="/">
            <img alt="Spixnet" src="https://spixnet.com/images/Spixnet-Schriftzug_dark-blue.webp" className="logo align-middle" />
            </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link className='nav-link' to="/" onClick= {() => document.getElementById('solutions')?.scrollIntoView({ behavior: 'smooth' })}>Solutions</Link>
                </li>
                <li className="nav-item">
                    <Link className='nav-link' to="/" onClick= {() => document.getElementById('carouselExampleInterval')?.scrollIntoView({ behavior: 'smooth' })}>Customers</Link>
                </li>
                
                <li className="nav-item">
                <Link className='nav-link' to="/" onClick= {() => document.getElementById('carouselExampleInterval2')?.scrollIntoView({ behavior: 'smooth' })}>News</Link>
                </li>
                <li className="nav-item">
                <Link className='nav-link' to="/" onClick= {() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}>Contact</Link>
                </li>
            </ul>
        </div>
    </div>
</nav>
}

export default Header;