import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

function Sidebar(){
    let navigate = useNavigate();
    const categoryurl = "https://spixnet.com/blogadmin/api/categories?SPIXNET-API-KEY=3690cec4aa3fe2f30573d1ea72ab7776";
    const tagsurl = "https://spixnet.com/blogadmin/api/tags?SPIXNET-API-KEY=3690cec4aa3fe2f30573d1ea72ab7776";
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);

    const fetchCategories = () => {        
        return fetch(categoryurl).then((res) => res.json()).then((d) => setCategories(d))
    }

    const fetchTags = () => {        
        return fetch(tagsurl).then((res) => res.json()).then((d) => setTags(d))
    }
    
    useEffect(() => {
        fetchCategories(); 
        fetchTags();        
    }, [])

    const handleKeyDown = (e) =>{
        if (e.key === 'Enter') {
            var searchkey = document.getElementById('search').value;
            if(searchkey){
                navigate("/cybersecurity-blog/" + searchkey);
            }
        }
    }

    return <div>
        <div className='search'>
            <h2>SEARCH</h2>
            <input type='text' name='search' id='search' onKeyDown={handleKeyDown} />
        </div>
        {categories?(
            <div>
                <h2>CATEGORIES</h2>
                <ul>
                    {categories.map((categoryObj, index) => {
                        var link = "/cybersecurity-blog/category/" + categoryObj.slug;
                        return <li key={index}>
                            <Link to={link} onClick= {() => window.scrollTo(0, 0)}>
                                <h3>{categoryObj.title}</h3>
                            </Link>
                        </li>
                    })}
                </ul>
            </div>   
        ):(
            <div></div>
        )}
        {tags?(
            <div>
                <h2>TAGS</h2>
                <ul>
                    {tags.map((tagObj, index) => {
                        var link = "/cybersecurity-blog/tag/" + tagObj.slug;
                        return <li key={index}>
                            <Link to={link} onClick= {() => window.scrollTo(0, 0)}>
                                <h3>{tagObj.title}</h3>
                            </Link>
                        </li>
                    })}
                </ul>
            </div>
        ):(
            <div></div>
        )}
        

    </div>
}

export default Sidebar;