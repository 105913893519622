import { Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Content from './components/Content';
import Impressum from './components/Impressum';
import Agb from './components/Agb';
import Datenschutz from './components/Datenschutz';
import Blogdetails from './components/Blogdetails';
import Blogs from './components/Blogs';
import { Helmet } from 'react-helmet';
import Payment from './components/Payment';

function App() {
  
    const [userinfo, setUserinfo] = useState([]);

    const getIPinfo = async () => {
      try{
        const ipinfo = await fetch('https://geolocation-db.com/json/').catch(error => console.log('error', error));
        const data = await ipinfo.json();        
        if(data.country_code != 'undefined'){
            setUserinfo(data);
        }
      }catch(e){}      
    }

    const callEachPage = (page) =>{   
        var uuid = getCookie();
        if(uuid == ''){
          var uuid = create_UUID();
          saveCookie(uuid);
        }
        if(uuid != ''){
            fetchInfo2(page, uuid);
        }
    }

    const fetchInfo2 = async (page, uuid) => {
      
        var myHeaders = new Headers();
        myHeaders.append("SPIXNET-API-KEY", "3690cec4aa3fe2f30573d1ea72ab7776");
        var formdata = new FormData();
        formdata.append("page", page);
        formdata.append("uuid", uuid);
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        fetch("https://spixnet.com/blogadmin/api/trackpage", requestOptions)
            .then(response => response.text())
            .then(result => {})
            .catch(error => console.log('error', error));
    
  }
    const fetchInfo = async (uuid) => {        
        //return await fetch(url+uuid).then((res) => res.json()).then((d) => '')
        if(userinfo.country_code != null){               
          var myHeaders = new Headers();
          myHeaders.append("SPIXNET-API-KEY", "3690cec4aa3fe2f30573d1ea72ab7776");
          //myHeaders.append('Content-Type', 'application/json');
          var formdata = new FormData();
          formdata.append("country_code", userinfo.country_code);
          formdata.append("country_name", userinfo.country_name);
          formdata.append("city", userinfo.city);
          formdata.append("postal", userinfo.postal);
          formdata.append("latitude", userinfo.latitude);
          formdata.append("longitude", userinfo.longitude);
          formdata.append("IP", userinfo.IPv4);
          formdata.append("uuid", uuid);
          
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formdata
          };
          fetch("https://spixnet.com/blogadmin/api/track", requestOptions)
              .then(response => response.text())
              .then(result => {})
              .catch(error => console.log('error', error));
      }
    }

  const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
  const getCookie = () => {
    let name = "uuid=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const saveCookie = (uuid) => {
    const d = new Date();
    d.setTime(d.getTime() + (12*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = "uuid=" + uuid + ";" + expires + ";path=/";
  }


  useEffect(() => {
    getIPinfo();
  }, [])
  useEffect(() => {
    var uuid = getCookie();
    if(uuid == ''){
      var uuid = create_UUID();
      saveCookie(uuid);
    }
    if(uuid != ''){
      const intervalCall = setInterval(() => {
        fetchInfo(uuid);
      }, 2000);
      
    }
    
  })

  return (
    <div>
    <Helmet>
        <title>Spixnet</title>
        <meta property='og:image' content="https://spixnet.com/images/Spixnet-Schriftzug_dark-blue.webp" />
      </Helmet>
    <Header/>
    <Routes>
        <Route path="/" element={<Content func={callEachPage}/>} />
        <Route path="/impressum" element={<Impressum func={callEachPage} />} />
        <Route path="/agb" element={<Agb  func={callEachPage}/>} />
        <Route path="/datenschutz" element={<Datenschutz func={callEachPage} />} />
        <Route path="/cybersecurity-blog/:d/:m/:y/:slug" element={<Blogdetails func={callEachPage} />} />
        <Route path="/cybersecurity-blog" element={<Blogs func={callEachPage} />} />
        <Route path="/cybersecurity-blog/category/:category" element={<Blogs func={callEachPage} />} />
        <Route path="/cybersecurity-blog/tag/:tag" element={<Blogs func={callEachPage} />} />
        <Route path="/cybersecurity-blog/:search" element={<Blogs func={callEachPage} />} />
        <Route path="/payment" element={<Payment/>} />
    </Routes>
    <Footer />
    </div>
  );
}

export default App;
