import { Link } from "react-router-dom";

function Mightlike(props){
   

    return <div>
        {props.list.map((obj, index)=>{

            const newlink = obj.created_date.replace(/-/g, '/');

    let image = "https://spixnet.com/blogadmin/images/" + obj.image;
    let link = "/cybersecurity-blog/" + newlink + '/' + obj.slug;


            return <div className="blogbox" key={index}>
        <Link to={link} onClick= {() => window.scrollTo(0, 0)}>
            <div className="imgbox" style={{ backgroundImage: `url("${image}")` }}></div>            
        </Link>
        <div className="contentbox">
            <h4 className="hide">{obj.category}</h4>
            <Link to={link} onClick= {() => window.scrollTo(0, 0)}><h2>{obj.title}</h2></Link>
        </div>
        <div className="date hide">
            <h6>{obj.created_date}</h6>
        </div>
    </div>
        })}
        </div>
}

export default Mightlike;