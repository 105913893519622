import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Blogentry from './Blogentry';
import Sidebar from "./Sidebar";

function Blogs(props){
    let {category} = useParams();
    let {tag} = useParams();
    let {search} = useParams();
    const location = useLocation()

    const [blogList, setBlogList] = useState(null);
    const [categoryTitle, setCategoryTitle] = useState(null);
    const [tagTitle, setTagTitle] = useState(null);
    const [searchTitle, setSearchTitle] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [nPages, setnPages] = useState(null);
    const [numbers, setNumbers] = useState(null);
    const recordPerPage = 45;

    const loadBlogs = async () => {

        const response = await fetch(
        `https://spixnet.com/blogadmin/api/blogs?SPIXNET-API-KEY=3690cec4aa3fe2f30573d1ea72ab7776&page=${currentPage}&count=${recordPerPage}&category=${category}&tag=${tag}&search=${search}`
        );
        const results = await response.json();
        
        setBlogList(results.blogs);
        if(category){
            setCategoryTitle(results.title);
        }else{
            setCategoryTitle('');
        }
        if(tag){
            setTagTitle(results.title);
        }else{
            setTagTitle('');
        }
        
        if(search){
            setSearchTitle(search);
        }else{
            setSearchTitle('');
        }
        const npage = Math.ceil(results.total/recordPerPage);
        setnPages(npage);
        setNumbers([...Array(npage + 1).keys()].slice(1));
    };

    useEffect(() => {
        loadBlogs();
        const interval = setInterval(() => {
            if(category){
                //props.func(category);
            }
            if(tag){
                //props.func(tag);
            }            
            if(search){
                //props.func(search);
            }
        }, 2000);
        return () => clearInterval(interval);        
    },[location], [currentPage]); // <= Add here

    const prevPage = () =>{
        setBlogList(null)
        if(currentPage > 0){
            setCurrentPage(currentPage - 1);
        }
    }
    const nextPage = () =>{
        setBlogList(null)
        if(currentPage < nPages){
            setCurrentPage(currentPage + 1);
        }
    }
    const changePage = (id) => {
        setBlogList(null)
        setCurrentPage(id);
    }

    if (blogList === null) {
        return <div className="container-fluid blogsList" id="blogs">
                    <div className="">
                        <div className="row">
                            <div className="col">
                                <div className='loading'>
                                    <img src='https://spixnet.com/images/loading2.gif' alt='Loading..' />
                                </div>

                                <ul className='pagination justify-content-center'>
                                    <li className="page-item">
                                        <a className="page-link" 
                                            onClick={prevPage} 
                                            href='#'>
                                            
                                            Previous
                                        </a>
                                    </li>
                                    <li className="page-item">
                                        <a className="page-link" 
                                            onClick={nextPage}
                                            href='#'>
                                            
                                            Next
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>;
    }

    return <div className="container blogsList" id="blogs">
            <div className="">
                <div className="row">
                    <div className="col-xl-9 col-lg-9">
                        <div id="blogs-list">
                        <div className="title">{categoryTitle?<h2>Cagetory: {categoryTitle}</h2>:""}{tagTitle?<h2>Tag: {tagTitle}</h2>:""}{searchTitle?<h2>Search Results for "{searchTitle}"</h2>:""}</div>
                            <div className="slidercontent2 fullwidth">
                            
                            {blogList.map((obj, index) => {
                               
                                return <Blogentry 
                                        title={obj.title} 
                                        category= {obj.category}
                                        slug = {obj.slug}
                                        date= {obj.created_date}
                                        image={obj.image}
                                        description={obj.description}
                                        key={index}
                                        />
                            })}
                            </div>

                            
                            <ul className='pagination'>
                                <li className={`page-item ${currentPage == 0 ? 'hide' : ''} `}>
                                    <a className="page-link"
                                        onClick={prevPage} 
                                        href='#'>
                                        
                                        Previous
                                    </a>
                                </li>
                                <li className={`page-item ${currentPage == nPages || currentPage < numbers ? 'hide' : ''} `}>
                                <a className="page-link" 
                                        onClick={nextPage}
                                        href='#'>
                                        
                                        Next
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 sidebar">
                        <Sidebar />
                    </div>
                </div>
            </div>
            
           

           
        </div>;


}

export default Blogs;